@use "../styles/variables";
@use "../styles/forms.scss";
@import "styles/variables";

.vendor-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.vendor-info-input {
  width: 50%;
}

.field {
  @extend %accounting-text-input;
  width: 95%;

  &-bold {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
  }
}

.vendor-notes {
  width: 100%;
}

.notes {
  @extend %accounting-text-input;
  height: 12em;

  &-task-texarea {
    box-sizing: border-box;
    background-color: variables.$lightGray25;
    border-radius: variables.$borderRadius;
    color: variables.$white;
    padding: 12px 15px;
    transition: 0.2s ease-in-out;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
      transition: all 0.2s;
    }
    height: 12em;
    resize: none;
  }

  &-container {
    display: flex;
    width: 100%;
    height: fit-content;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #EFEFEF;
    margin: 8px 0 36px;
  }
}

.task-input{
  width: 20%;
}

.container-field{
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 30px;
  &-text {
    letter-spacing: 4px;
    white-space: nowrap;
    margin-right: 10px;
  }
}

.main-title{
  margin-top: 35px;
  margin-bottom: 35px;
  margin-right: 20px;
}

.no-margin{
  margin: 0
}

.border-radius-medium{
  border-radius: 20px;
}

.select-form{
  background-color: variables.$lightGray25;
  border: 1px solid variables.$white;
  border-radius: 20px;
  color: variables.$white;
  font-family: variables.$korolevMed;
  font-size: 15px;
  padding: 10px 12px;
  width: 95%;
}