@import 'src/styles/variables';

.MuiPagination-root {
  button,
  div {
    color: $white;
    font-family: $magistralCondMed;
    &:hover {
      color: $lightGray50;
    }
  }
}

.css-bf9wz-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: $white;
  background-color: $lightGray50 !important;
}
