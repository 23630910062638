@use "../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 36px;
  padding-top: 0;
}

.note-field {
  margin-top: 25px;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: variables.$borderRadius;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: variables.$borderRadius;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: variables.$borderRadius;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.vendor-fields {
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 500px;
}

.vendor-field {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 13px;
  align-items: center;

  & > :first-child {
    width: 16%;
    margin: 0;
  }
}

.vendor-budgets {
  position: relative;
  margin-right: -40px;
  &__table {
    &-head {
      display: flex;
      flex-direction: row;
      width: 100%;

      &--item {
        display: flex;
        align-items: center;
        flex-basis: 7%;
        height: 50px;
        font-weight: 800;
        color: rgba(255, 255, 255, 0.3);
        padding-left: 8px;
        border-bottom: solid 2px rgba(255, 255, 255, 0.2);

        &:nth-child(2) {
          flex-basis: 4%;
        }

        &:nth-child(3) {
          flex-basis: 5%;
        }

        &:nth-child(4) {
          flex-basis: 5%;
        }

        &:nth-child(5) {
          flex-basis: 8%;
        }

        &:nth-child(6) {
          flex-basis: 12%;
        }

        &:nth-child(7) {
          flex-basis: 52%;
        }
      }
    }

    &-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      border-top: 1px solid rgba(255, 255, 255, 0.1);

      &:nth-child(1) {
        border: none;
      }

      &--item {
        display: flex;
        align-items: center;
        flex-basis: 7%;
        height: fit-content;
        padding: 10px 0 10px 8px;

        &:nth-child(2) {
          flex-basis: 4%;
        }

        &:nth-child(3) {
          flex-basis: 5%;
        }

        &:nth-child(4) {
          flex-basis: 5%;
        }

        &:nth-child(5) {
          flex-basis: 8%;
          overflow-wrap: anywhere;
        }

        &:nth-child(6) {
          flex-basis: 12%;
        }

        &:nth-child(7) {
          flex-basis: 52%;
        }
      }

      &--month {
        display: flex;
        flex-direction: row;
        gap: 3px;
        height: 21px;
        padding-right: 3px;

        &--open {
          justify-content: space-between;
        }

        & span {
          width: 31px;
        }
      }

      &--input {
        width: calc(100% - 35px);
      }

      &--container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 3px 0;
      }
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }

    &-button {
      color: variables.$white;
      background-color: transparent;
      padding: 0;
      border: none;
      font-family: variables.$korolevMed;
      font-size: 16px;
      transition: all 0.3s;
    }

    &-edit {
      &:hover {
        color: variables.$highlightBlue;
      }
    }

    &-submit {
      &:hover {
        color: variables.$submitGreen;
      }
    }

    &-delete {
      &:hover {
        color: variables.$cancelRed;
      }
    }
  }
  &-month {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 600px;
  }

  &-create {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 500px;

    &-tabs {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      border-bottom: 1px solid variables.$black;
      margin: 0 0 5px;

      &-import {
        margin: 0 0 30px;
      }
    }
  }

  &-error--1 {
    position: absolute;
    top: 67px;
    right: 10px;
    color: variables.$cancelRed;
    font-size: 12px;
  }

  &-error--2 {
    position: absolute;
    top: 154px;
    right: 10px;
    color: variables.$cancelRed;
    font-size: 12px;
  }

  &-error--3 {
    position: absolute;
    top: 212px;
    right: 10px;
    color: variables.$cancelRed;
    font-size: 12px;
  }

  &-error--4 {
    position: absolute;
    z-index: 1;
    top: 270px;
    right: 10px;
    color: variables.$cancelRed;
    font-size: 12px;
  }

  &-error--5 {
    position: absolute;
    z-index: 1;
    top: 406px;
    right: 10px;
    color: variables.$cancelRed;
    font-size: 14px;
  }

  &-error--6 {
    position: absolute;
    z-index: 1;
    top: 176px;
    right: 10px;
    color: variables.$cancelRed;
    font-size: 14px;
  }
}
