@import "styles/variables";

.connection {
  &__modal {
    &-content {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 665px;

      & textarea {
        border-radius: 0;
      }
    }

    &-title {
      color: $black;
      font-family: $magistralCondMed;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: start;

      &--italic {
        font-family: $magistralLightItalic;
        font-style: italic;
      }

      &--bold {
        font-family: $magistralCondMed;
        font-style: normal;
        font-weight: 500;
      }

      &--margin {
        margin-top: 20px;
      }
    }

    &-tags {
      display: flex;
      flex-direction: row;
      gap: 20px;

      &--box {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      &--title {
        font-family: $magistralCondMed;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-align: start;
      }
      &--layout {
        height: fit-content;
        width: fit-content;
        border-radius: 10px;
        overflow: hidden;
      }

      &--container {
        display: flex;
        flex-direction: column;
        gap: 3px;
        width: 300px;
        height: 266px;
        margin-bottom: 20px;
        padding: 4px 0;
        background-color: #fff;
        border: 1px solid #a5af96;
        border-radius: 10px;
        overflow-y: auto;

        &::-webkit-scrollbar-thumb {
          background: #818181;
          border-radius: 10px !important;

          &:hover {
            background: #626262;
          }
        }

        &::-webkit-scrollbar-track {
          background: #ababab !important;
        }

        &--top {
          padding: 0;
          height: 32px;
          margin-bottom: 10px;
        }

        &--bottom {
          height: 180px;
          margin-bottom: 0;
        }
      }

      &--arrows {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;

        &-box {
          display: flex;
          flex-direction: column;
          gap: 3px;
          margin-bottom: 60px;
        }

        & i {
          margin: 0;
          color: $highlightPurple;
          cursor: pointer;

          &:hover {
            color: #00b900;
            opacity: 0.8;
          }

          &:nth-child(2):hover {
            color: #cc0000;
          }
        }
      }
    }

    &-tag {
      padding: 4px 15px;
      color: $black;
      font-size: 16px;
      text-align: start;
      border: solid #dedede;
      border-width: 1px 0;
      background-color: transparent;

      &:hover {
        background-color: #e1e1e1;
      }

      &--active {
        background: linear-gradient(to right, rgba(239, 239, 239, 0), rgba(83, 28, 179, 0.3411764706));
      }
    }

    &-buttons {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-self: flex-end;
    }

    &-button {
      height: 50px;
      padding: 0 30px;
      color: $white;
      font-family: "MagistralCondW08-Medium", sans-serif;
      font-size: 18px;
      font-weight: 500;
      border: 1px solid $highlightNavy;
      background-color: $highlightNavy;
      border-radius: 10px;
      transition: all 0.3s;

      &:hover {
        background-color: $white;
        border: 1px solid $highlightNavy;
        color: $highlightNavy;
      }

      &--back {
        color: $black;
        border: 1px solid transparent;
        background-color: #bbbbbb;

        &:hover {
          color: $black;
          border: 1px solid $black;
          background-color: #d0d0d0;
        }
      }

      &--small {
        width: 132px;
        height: 44.8px;
        margin-top: 35px;
        font-size: 15px;
      }
    }

    &-inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-fields {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 15px;
      width: 100%;
      height: 80px;
    }

    &-field {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-selectors {
      display: flex;
      flex-direction: column;
      gap: 25px;
      margin: 50px 0 177px;
    }

    &-select {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      width: 100%;
      border: none;
      background-color: transparent;

      &--box {
        display: flex;
        min-width: 20px;
        height: 20px;
        border: 1px solid #a5af96;
        border-radius: 50%;
        background-color: #ffffff;
      }

      &--circle {
        display: flex;
        min-width: 12px;
        height: 12px;
        margin: auto;
        border-radius: 50%;
        background-color: $highlightNavy;
      }
    }

    &-connections {
      display: flex;
      flex-direction: column;
      height: 220px;
      margin: -3px 0;
      overflow: auto;
      border-radius: 10px;
      border: 2px solid $black;

      & .connection__modal-connection {
        border-bottom: 1px solid $black;
      }

      & :last-child {
        border-bottom: none;
      }
    }

    &-connection {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      padding: 6px 15px;
      color: $black;
      text-align: start;
      background-color: transparent;
      border: none;
      transition: all 0.3s;

      &:hover {
        color: $white;
        background-color: #141a6685;
      }

      &-active {
        color: $white;
        background-color: $highlightNavy;
      }
    }

    &-spinner {
      position: absolute;
      top: 3px;
      left: -60px;
      max-height: 50px;

      & :first-child {
        margin: 0;
      }

      &-two {
        position: absolute;
        right: 134px;
        top: -3px;
      }
    }
  }
}