@use "../styles/variables";

.loading-screen {
  background-image: linear-gradient(variables.$primaryGreen, variables.$black);
  color: variables.$white;
  font-family: variables.$korolevMed;
  min-height: calc(100vh - 80px);
  padding-top: 100px;
  width: 100%;

  &-blue {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(variables.$primaryBlue, variables.$black);
  }
}
