@import "../styles/variables";

.tableLayout {
  text-align: left;
  position: relative;
  border-collapse: collapse;

  &__th {
    position: sticky;
    top: 0;
    background-color: #26351d;
    padding-bottom: 10px;
    border-bottom: 1px solid transparent;
    z-index: 2;

    &--blue {
      background-color: #091226;
    }

    &:after{
      content:'';
      position:absolute;
      left: 0;
      bottom: 0;
      width:100%;
      border-bottom: 1px solid #efefef80;
    }
  }

  &__th-div {
    position: sticky;
    top: 0;
    background-color: #26351d;
    padding-bottom: 10px;
    border-bottom: 1px solid transparent;
    z-index: 3;

    &--blue {
      background-color: #091226;
    }

    &--darkGreen {
      background-color: #143037;
    }

    &:after{
      content:'';
      position:absolute;
      left: 0;
      bottom: 0;
      width:100%;
      border-bottom: 1px solid #efefef80;
    }
  }
}