@import "styles/variables";

.applicant {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__section {
    width: 100%;
    padding-bottom: 15px;

    &-h2 {
      color: $white;
      font-family: $magistralCondMed;
      font-size: 20px;
      text-transform: uppercase;
    }

    &-p {
      color: $white;
      font-family: $magistralCondMed;
      font-size: 16px;
      width: 50%;

      &--width {
        width: auto;
        font-family: $magistralCondMed;
      }
    }

    &-top {
      display: flex;
      gap: 20px;
      align-items: baseline;
      margin-bottom: 8px;
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &-row {
      display: flex;
      align-items: center;

      &--gap {
        display: flex;
        align-items: baseline;
        gap: 10px;
        width: 50%;
      }
    }
  }

  &__edit-loading span {
    margin: 0;
  }

  &__date {
    margin: 8px 0 4px;
    width: 100%;
    background-color: transparent;
    border: 1px solid #b0b0b0;
    border-radius: 4px;
    font-size: 16px;
    line-height: 21.5px;
    color: $black;
    padding: 16.5px 14px;
    font-family: "Roboto", sans-serif;

    &:hover {
      border-color: black;
    }

    &-message {
      position: absolute;
      top: 31px;
      right: 4px;
      color: $primaryGreen;
      font-family: $korolevMed;
      font-size: 11px;
    }
  }
}