@use "../styles/variables";

.infoForm {
  &-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #262a25;
    border-radius: 28px;
    box-shadow: 0 0 16px 27px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    padding: 32px;
    max-width: 700px;
  }

  &-h1 {
    color: #dae0bc;
    font-family: variables.$magistralCondMed;
    font-size: 35px;
    font-weight: 500;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
  }

  &-h2 {
    color: #a5af96;
    font-family: variables.$magistralCondMed;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;

    &:nth-child(3) {
      margin-top: 15px;
    }
  }

  &-p {
    color: #EFEFEF;
    font-family: variables.$korolevMed;
    font-size: 16px;
    max-width: 70%;
  }

  &-fieldName {
    color: #dae0bc;
    font-family: variables.$korolevMed;
    font-size: 0.95rem;
    font-weight: 500;
    text-transform: capitalize;
  }

  &-error {
    position: absolute;
    right: 0;
    top: -1.5px;
    color: #a20601;
    font-family: variables.$magistralCondMed;
  }

  &-column {
    display: flex;
    flex-direction: column;
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-inputContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    width: 100%;
  }
}