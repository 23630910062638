@import "styles/variables";

.sales-files-list {
  display: flex;
  flex-flow: wrap;
  max-height: 75%;
  overflow: auto;
  width: 100%;
}
.sales-file {
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
  margin-right: 21px;
  width: 125px;
}

.sales-viewFile {

  & .create-button {
    background-color: $highlightNavy;
    border-color: $lightGray50;
    margin: 0;

    &:hover {
      background-color: transparent;
    }
  }
}
