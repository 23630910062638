//@import url(//db.onlinewebfonts.com/c/3d5f94badc5c3a15e620a9da9089b726?family=MagistralCondW08-Medium);
@font-face {
  font-family: "MagistralCondW08-Medium";
  src: url("https://db.onlinewebfonts.com/t/3d5f94badc5c3a15e620a9da9089b726.eot");
  src: url("https://db.onlinewebfonts.com/t/3d5f94badc5c3a15e620a9da9089b726.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/3d5f94badc5c3a15e620a9da9089b726.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/3d5f94badc5c3a15e620a9da9089b726.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/3d5f94badc5c3a15e620a9da9089b726.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/3d5f94badc5c3a15e620a9da9089b726.svg#MagistralCondW08-Medium")
      format("svg");
  font-display: swap;
}

//@import url(//db.onlinewebfonts.com/c/8fc9410d385ce47cf03dd816f3bfd1db?family=MagistralW01-CondBold);
//@font-face {
//  font-family: "MagistralW01-CondBold";
//  src: url("https://db.onlinewebfonts.com/t/8fc9410d385ce47cf03dd816f3bfd1db.eot");
//  src: url("https://db.onlinewebfonts.com/t/8fc9410d385ce47cf03dd816f3bfd1db.eot?#iefix")
//      format("embedded-opentype"),
//    url("https://db.onlinewebfonts.com/t/8fc9410d385ce47cf03dd816f3bfd1db.woff2")
//      format("woff2"),
//    url("https://db.onlinewebfonts.com/t/8fc9410d385ce47cf03dd816f3bfd1db.woff")
//      format("woff"),
//    url("https://db.onlinewebfonts.com/t/8fc9410d385ce47cf03dd816f3bfd1db.ttf")
//      format("truetype"),
//    url("https://db.onlinewebfonts.com/t/8fc9410d385ce47cf03dd816f3bfd1db.svg#MagistralW01-CondBold")
//      format("svg");
//}

//@import url(//db.onlinewebfonts.com/c/479db221f0230d8e10c5bf8c95695c7c?family=MagistralW01-Bold);
//@font-face {
//  font-family: "MagistralW01-Bold";
//  src: url("//db.onlinewebfonts.com/t/479db221f0230d8e10c5bf8c95695c7c.eot");
//  src: url("//db.onlinewebfonts.com/t/479db221f0230d8e10c5bf8c95695c7c.eot?#iefix")
//      format("embedded-opentype"),
//    url("//db.onlinewebfonts.com/t/479db221f0230d8e10c5bf8c95695c7c.woff2")
//      format("woff2"),
//    url("//db.onlinewebfonts.com/t/479db221f0230d8e10c5bf8c95695c7c.woff")
//      format("woff"),
//    url("//db.onlinewebfonts.com/t/479db221f0230d8e10c5bf8c95695c7c.ttf")
//      format("truetype"),
//    url("//db.onlinewebfonts.com/t/479db221f0230d8e10c5bf8c95695c7c.svg#MagistralW01-Bold")
//      format("svg");
//}

@font-face {
  font-family: "Magistral-Light-Italic";
  font-style: italic;
  src: url("//db.onlinewebfonts.com/t/944323071d8508f98bf93320fe49587e.eot");
  src:
    url("//db.onlinewebfonts.com/t/944323071d8508f98bf93320fe49587e.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/944323071d8508f98bf93320fe49587e.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/944323071d8508f98bf93320fe49587e.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/944323071d8508f98bf93320fe49587e.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/944323071d8508f98bf93320fe49587e.svg#Magistral")
      format("svg");
}

//@import url(//db.onlinewebfonts.com/c/f957ee5b11738389e5b0012a734390e6?family=Korolev+Medium);
@font-face {
  font-family: "Korolev Medium";
  src: url("https://db.onlinewebfonts.com/t/f957ee5b11738389e5b0012a734390e6.eot");
  src: url("https://db.onlinewebfonts.com/t/f957ee5b11738389e5b0012a734390e6.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/f957ee5b11738389e5b0012a734390e6.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/f957ee5b11738389e5b0012a734390e6.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/f957ee5b11738389e5b0012a734390e6.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/f957ee5b11738389e5b0012a734390e6.svg#Korolev Medium")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Korolev Light";
  src: url("https://db.onlinewebfonts.com/t/20e17a0bd3c4791993bf60d5e581ae7d.eot");
  src: url("https://db.onlinewebfonts.com/t/20e17a0bd3c4791993bf60d5e581ae7d.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/20e17a0bd3c4791993bf60d5e581ae7d.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/20e17a0bd3c4791993bf60d5e581ae7d.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/20e17a0bd3c4791993bf60d5e581ae7d.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/20e17a0bd3c4791993bf60d5e581ae7d.svg#Korolev Light")
      format("svg");
  font-display: swap;
}
//@font-face {
//  font-family: "Korolev Thin";
//  src: url("//db.onlinewebfonts.com/t/15b29e6d668f34e2df27e0844d98995c.eot");
//  src: url("//db.onlinewebfonts.com/t/15b29e6d668f34e2df27e0844d98995c.eot?#iefix")
//      format("embedded-opentype"),
//    url("//db.onlinewebfonts.com/t/15b29e6d668f34e2df27e0844d98995c.woff2")
//      format("woff2"),
//    url("//db.onlinewebfonts.com/t/15b29e6d668f34e2df27e0844d98995c.woff")
//      format("woff"),
//    url("//db.onlinewebfonts.com/t/15b29e6d668f34e2df27e0844d98995c.ttf")
//      format("truetype"),
//    url("//db.onlinewebfonts.com/t/15b29e6d668f34e2df27e0844d98995c.svg#Korolev Thin")
//      format("svg");
//}
//@font-face {
//  font-family: "Korolev Heavy";
//  src: url("//db.onlinewebfonts.com/t/974532ce2bfedaf7220724d026439c92.eot");
//  src: url("//db.onlinewebfonts.com/t/974532ce2bfedaf7220724d026439c92.eot?#iefix")
//      format("embedded-opentype"),
//    url("//db.onlinewebfonts.com/t/974532ce2bfedaf7220724d026439c92.woff2")
//      format("woff2"),
//    url("//db.onlinewebfonts.com/t/974532ce2bfedaf7220724d026439c92.woff")
//      format("woff"),
//    url("//db.onlinewebfonts.com/t/974532ce2bfedaf7220724d026439c92.ttf")
//      format("truetype"),
//    url("//db.onlinewebfonts.com/t/974532ce2bfedaf7220724d026439c92.svg#Korolev Heavy")
//      format("svg");
//}
@font-face {
  font-family: "Korolev Bold";
  src: url("https://db.onlinewebfonts.com/t/96326733fb4665a821d9fc7a295a750c.eot");
  src: url("https://db.onlinewebfonts.com/t/96326733fb4665a821d9fc7a295a750c.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/96326733fb4665a821d9fc7a295a750c.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/96326733fb4665a821d9fc7a295a750c.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/96326733fb4665a821d9fc7a295a750c.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/96326733fb4665a821d9fc7a295a750c.svg#Korolev Bold")
      format("svg");
  font-display: swap;
}

// Fonts
// TODO: Replace Magistral Cond with Magistral Bold
$magistralCondMed: "MagistralCondW08-Medium";
$magistralCondBold: "MagistralW01-CondBold";
$magistralBold: "MagistralW01-Bold";
$magistralLightItalic: "Magistral-Light-Italic";
$korolevMed: "Korolev Medium";

// Base Colors
$white: #EFEFEF;
$lightGray10: #EFEFEF10;
$lightGray25: #EFEFEF40;
$lightGray50: #EFEFEF80;
$lightGray75: #D0D0D0B5;
$black: #171717;

// Staffing Color Palette
$primaryGreen: #2A3D1F;
$highlightGreen: #62AB37;

// Accounting Color Palette
$primaryBlue: #12343D;
$highlightBlue: #00A5CF;

// Sales Color Palette
$primaryPurple: #26183E;
$highlightPurple: #531CB3;

// Submit/Cancel Buttons
$submitGreen: #60992d;
$cancelRed: #a20601;
$pendingYellow: #fbe888;
$pendingOrange: #ff8400;

// Previous Executive Black Color Palette
$midGray: #7a7a7a;

// Borders
$accountingBorder: 1px solid $lightGray50;
$borderRadius: 10px;

// Projects Color Palette
$primaryNavy: #07122A;
$highlightNavy: #141A66;
$projectsAqua: #A4F2F1;
