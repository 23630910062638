@use "./variables";

button {
  cursor: pointer;
}

// Accounting Module

.accounting-primary-button {
  background-color: variables.$lightGray50;
  border: none;
  border-radius: variables.$borderRadius;
  color: variables.$white;
  font-family: variables.$magistralCondMed;
  font-size: 20px;
  height: 54px;
  min-width: 388px;
  padding: 13px 24px;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: variables.$white;
    color: variables.$black;
  }
}
