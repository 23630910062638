@import "styles/variables";

.vendor-grouping {
  position: relative;

  &__content {
    border: $accountingBorder;
    border-radius: $borderRadius;
    height: calc(100vh - 246px);
    overflow-y: auto;
  }

  &__expanded {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding: 10px 15px 20px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
  }

  &__input {
    background-color: #fff;
    border: 1px solid #a5af96;
    border-radius: 8px;
    padding: 6px 10px 5px;
    outline: none;
    font-size: 16px;
  }

  &__name {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 25%;
  }

  &__title {
    font-weight: 600;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 75%;
  }

  &__accounts {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &--container {
      display: flex;
      flex-flow: row wrap;
      gap: 10px 25px;
    }
  }

  &__account {
    display: flex;
    flex-direction: row;
    gap: 5px;

    &--dismiss {
      display: flex;
      color: red;
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      padding: 0;
      margin: 0;
      border: none;
      background-color: transparent;
    }

    &--create {
      display: flex;
      width: fit-content;
      color: white;
      font-weight: 600;
      padding: 10px 20px;
      margin: 0;
      border: 1px solid white;
      border-radius: 10px;
      background-color: transparent;
      transition: all 0.3s;

      &:hover {
        background-color: $lightGray25;
      }
    }

    &--delete {
      display: flex;
      padding: 5px;
      color: red;
      font-size: 30px;
      line-height: 15px;
      font-weight: 600;
      margin: 0;
      border: none;
      background-color: transparent;
    }

    &--add {
      display: flex;
      padding: 5px;
      color: green;
      font-size: 30px;
      line-height: 14px;
      font-weight: 600;
      margin: 0;
      border: none;
      background-color: transparent;
    }

    &--number {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      height: 39px;

      &--error {
        position: absolute;
        top: 40px;
        font-size: 12px;
        color: red;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &__create {
    position: absolute;
    top: 2px;
    right: 0;
    display: flex;
    width: fit-content;
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 20px;
    margin: 0;
    border: 1px solid white;
    border-radius: 10px;
    background-color: transparent;
    transition: all 0.3s;

    &:hover {
      background-color: $lightGray25;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 400px;

    &-section {
      position: relative;
    }

    &-submit {
      display: flex;
      align-self: flex-end;
      width: fit-content;
      color: white;
      font-size: 18px;
      font-weight: 600;
      padding: 10px 20px;
      margin: 0;
      border: 1px solid white;
      border-radius: 10px;
      background-color: $highlightBlue;
      transition: background-color 0.3s;

      &:hover {
        background-color: $primaryBlue;
      }

      &:disabled {
        cursor: default;
        background-color: $midGray;
      }
    }

    &-import {
      font-size: 16px;
      border-radius: 10px 10px 0 0;
      border-bottom: none;
    }

    &-create {
      font-size: 16px;
      border-radius: 10px 10px 0 0;
      border-bottom: none;
    }

    &-error {
      position: absolute;
      top: 80px;
      font-size: 12px;
      color: red;
    }

    & .vendor-grouping__account--create {
      background-color: $highlightBlue;

      &:hover {
        background-color: $primaryBlue;
      }
    }
  }
}