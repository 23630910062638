@use "../styles/variables";
@use "../css/pages.scss";

.content-title {
  display: flex;
  width: 100%;
  font-size: 20px;
  text-align: left;
  align-content: center;
  margin-bottom: 0 !important;
}

.vendor-general-content-container {
  @extend %content-container;

  .row {
    gap: 30px;
    border: none !important;
  }

  .header {
    margin-top: 100px;
    width: 96.5%;
    border-top: 1px solid variables.$midGray !important;
    font-size: 25px;

    div {
      margin-top: 10px;
    }
  }

  .auto {
    margin-top: 10px;
  }
  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    .column-title {
      width: 100%;
    }

    .title-icons {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      margin-right: 10%;
      gap: 20px;

      .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        padding: 5px;
        color: variables.$white;
        transition: 0.3s ease-in !important;

        &:hover {
          color: variables.$pendingYellow;
        }
      }
      i {
        color: variables.$white;
        font-size: 30px !important;
        transition: 0.3s ease-in-out !important;
        text-shadow: variables.$white !important;

        &:hover {
          opacity: 0.5;
          color: #80e658;
        }
      }
    }
  }

  .vendor-input {
    padding: 10px;
  }

  select {
    width: 90%;
    height: 40px;
    background-color: variables.$midGray;
    border: 1px solid variables.$white;
    border-radius: variables.$borderRadius;

    option {
      color: variables.$white;
      background-color: #171717;
      font-size: 13px;
    }
  }
  .input-box {
    .field {
      width: 90%;
      height: 40px;
      color: variables.$white;
      background-color: variables.$midGray;
      border: 1px solid variables.$white;
      border-radius: variables.$borderRadius;
      padding: 10px;
    }
  }

  .edit-btn {
    color: variables.$white;
    transition: 0.3s ease-in-out !important;

    &:hover {
      color: variables.$midGray !important;
    }
  }
}

.heading-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .verified-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .pass {
    background-color: #60992d;
  }

  .fail {
    background-color: #a20601;
  }

  .pending {
    background-color: #fbe888;
  }
  .unmarked {
    background-color: variables.$midGray;
  }
  .enlarged {
    width: 18px;
    height: 18px;
  }
}

.helper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  h6 {
    font-weight: 100;
  }
}

.verify-all {
  font-size: 15px;
}

.submitted-text {
  margin-left: 10px;
  font-size: 20px;
  animation: effect 0.5s alternate infinite;
  .dot-1 {
    color: variables.$midGray;
  }
}

@keyframes effect {
  0% {
    color: variables.$white;
  }

  100% {
    color: variables.$midGray;
  }
}

.helper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  h6 {
    font-weight: 100;
  }
}

.heading-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .verified-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .pass {
    background-color: #60992d;
  }

  .fail {
    background-color: #a20601;
  }

  .pending {
    background-color: #fbe888;
  }
  .unmarked {
    background-color: variables.$midGray;
  }
  .enlarged {
    width: 18px;
    height: 18px;
  }
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: variables.$white;
  transition: 0.3s ease-in !important;

  &:hover {
    color: #fbe888;
  }
}
i {
  margin-left: 25px;
  color: variables.$white;
  font-size: 25px !important;
  transition: 0.3s ease-in-out !important;
  text-shadow: variables.$white !important;

  &:hover {
    opacity: 0.5;
    color: #80e658;
  }
}
