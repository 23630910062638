@import "src/styles/variables";

.login-background {
  background-attachment: fixed;
  background: linear-gradient(to top right, #171717, #2b2b2b);
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.login-form-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100vw;
  padding: 200px 0;
  gap: 0;
}

.login-form-card {
  width: 20%;
  background-color: $white;
  padding: 29px 65px 45px;
  box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.75) !important;
  border-radius: 0 !important;
  border-top-right-radius: 28px !important;
  border-bottom-right-radius: 28px !important;
}

.login-form-title {
  color: $black;
  font-family: $magistralCondMed;
  font-size: 35px;
  font-weight: 500;
  line-height: 50px;
  margin: 0 auto 35px;
  max-width: 325px;
}

.input-label {
  font-family: $korolevMed;
  text-align: left;
}

.login-form-inputs {
  background-color: $white;
}

.login-passing {
  color: transparent;
  font-size: 14px;
  margin-top: 15px;
}

.login-error {
  color: $black;
  font-family: $korolevMed;
  font-size: 14px;
  margin-top: 15px;
}

.login-form-buttons {
  margin: 45px auto 0;
  width: 175px;

  a {
    text-decoration: none;
  }
}

.directions-box {
  background-image: url("../assets/hexagon-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: calc(50% - 0px) 100%;
  font-family: $korolevMed;
  color: $white;
  width: 40%;
  padding: 200px 0;
  border-top-left-radius: 28px !important;
  border-bottom-left-radius: 28px !important;
  box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.75);

  h1 {
    font-family: $korolevMed;
    font-size: 40px;
  }

  p {
    margin-top: 30px;
    font-family: $korolevMed;
    font-size: 23px;
    overflow-wrap: break-word;
  }
}

.sign-in-btn {
  background-color: $black !important;
  color: $white !important;
  font-size: 18px !important;
  width: 175px !important;
  transition: 0.2s ease-in-out !important;

  &:hover {
    background-color: $white !important;
    color: $black !important;
  }
}

@media screen and (max-width: 1837px) {
  .login-background {
    min-height: 100% !important;
  }
  .login-form-container {
    .directions-box {
      width: 30% !important;
      h1 {
        font-size: 18px !important;
      }
      p {
        font-size: 13px !important;
      }
    }
    .login-form-card {
      width: 30% !important;
    }
  }
}

@media screen and (max-width: 1300px) {
  .login-form-container {
    .directions-box {
      width: 30% !important;
      h1 {
        font-size: 18px !important;
      }
      p {
        font-size: 13px !important;
      }
    }
    .login-form-card {
      width: 60% !important;
    }
  }
}

@media screen and (max-width: 1280px) {
  .login-form-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;

    .forgot-password-btn {
      font-size: 25px !important;
    }

    .directions-box {
      width: 100% !important;
      border-radius: 0 !important;

      h1 {
        font-size: 38px !important;
      }
      p {
        font-size: 23px !important;
      }
    }
    .login-form-card {
      width: 100% !important;
      border-radius: 0 !important;

      .input-label {
        margin-left: 25%;
      }
      .login-form-inputs {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  // .MuiPaper-root {
  //   background: $white !important;
  //   box-shadow: none !important;
  // }
  .login-background {
    background: $white !important;
  }
  .login-form-container {
    .forgot-password-btn {
      font-size: 23px !important;
    }
    .directions-box {
      padding-top: 150px !important;
      padding-bottom: 100px !important;

      h1 {
        font-size: 28px !important;
      }
      p {
        font-size: 15px !important;
      }
    }
    .login-form-card {
      box-shadow: none !important;
      .input-label {
        margin-left: 0;
      }
      .login-form-inputs {
        width: 100%;
      }
    }
  }
}

.reset-loader {
  position: absolute;
  top: -6px;

  &-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
