@import "styles/variables";

.email-templates {
  display: flex;
  flex-direction: row;

  &__container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 70%;
    border-right: $accountingBorder;

    &-thin {
      width: 30%;
      min-width: 380px;
      border: none;
    }

    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 0 20px;
      border-bottom: $accountingBorder;
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
    }

    &-box {
      display: flex;
      flex-direction: column;
      margin: 20px 13px 20px 20px;
      padding-right: 3px;
      height: 100%;
      max-height: calc(100% - 100px);
      overflow-y: auto;
    }
  }

  &__title {
    font-family: $magistralCondMed;
    font-size: 25px;
  }

  &__template {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: $accountingBorder;

    &-title {
      font-family: $magistralCondMed;
      font-size: 16px;
      max-width: calc(100% - 50px);
    }
  }

  & .ql-snow.ql-toolbar {
    background-color: $white;
    border-radius: 5px 5px 0 0;
  }

  & .ql-container.ql-snow {
    border-radius: 0 0 5px 5px;
  }

  & .ql-editor {
    min-height: 150px;
    max-height: calc(100vh - 580px);
  }

  & .MuiCircularProgress-root {
    margin: 0;
  }
}
