@use "../styles/variables";
.page-content {
  height: 100%;
}

.content-title {
  margin-bottom: 20px;
}

.all-content {
  display: flex;
  flex-direction: column;
  border: 1px solid variables.$midGray;
  height: 100% !important;
  border-radius: variables.$borderRadius;
  font-size: 18px;

  .row {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    color: variables.$white;
    padding: 20px;

    .choices {
      display: flex;
      width: 30%;
      justify-content: space-evenly;
      align-items: center;

      .verified-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      .pass {
        background-color: #60992d;
      }

      .fail {
        background-color: #a20601;
      }

      .pending {
        background-color: #fbe888;
      }
    }
  }

  .border {
    border-bottom: 1px solid variables.$midGray !important;
    padding: 15px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0 20px 15px;
    width: 100%;
    border-bottom: 1px solid variables.$midGray;
    margin-right: 25px;

    .btn {
      cursor: pointer;
      font-size: 15px;
      width: 3.5%;
      height: 25px;
      background-color: variables.$midGray;
      outline: none;
      border: none;
      border-radius: 3px;
      color: variables.$white;
      margin-bottom: 0;
      transition: 0.3s ease;
    }

    .edit {
      width: 8.5%;
      color: variables.$white;

      &:hover {
        background-color: variables.$black;
      }
    }

    .submit {
      display: block;
      margin-right: 25px;
      color: variables.$white;
      background-color: variables.$black;
      width: fit-content;
      height: auto;
      padding: 10px;

      &:hover {
        box-shadow: 0 0 4px 0 variables.$black;
        background-color: #60992d;
      }
    }

    .cancel {
      display: block;
      width: fit-content;
      background-color: #a20601;
      height: auto;
      padding: 10px;

      &:hover {
        color: variables.$black;
        box-shadow: 0 0 4px 0 variables.$black;
      }
    }

    .checkbox-icon {
      border-radius: 50%;
      font-size: 20px;
      margin-left: 25px;
      transition: 0.3s ease;
      cursor: pointer;
      color: variables.$white;

      &:hover {
        color: #fbe888;
        background-color: variables.$black;
      }
    }
  }
}

.end {
  padding-bottom: 0 !important;
}

.hide {
  opacity: 0 !important;
  width: 50%;
}

.show {
  outline: none;
  border-radius: 5px;
  border: none;
  height: 30px;
  width: 50%;
  text-align: left;
  padding: 5px;
  font-weight: 800;
  background-color: variables.$white;
}

.checkbox {
  padding: 0;
}
