@import "src/styles/variables";
html,
body {
  overflow-y: auto;
}
.splash-content-container {
  display: flex;
  align-items: center;
  color: $white;
  background-color: #5c5c5c;
  min-height: calc(100vh - 80px);
  width: 100%;

  .card-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 0;

    @media (min-width: 1200px) {
      flex-direction: row;
      gap: 50px;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      align-items: center;
      height: 30em;
      background-size: contain;
      border: 1px solid black;
      box-shadow: 0 1px 5px -1px black;
      transition: 0.2s ease-in-out;
      background: linear-gradient(
                      to bottom,
                      #171717,
                      #171717,
                      #232323,
                      #373737,
                      #595959 41%,
                      $white 41%
      );
      width: 100%;
      border-radius: 0;

      @media (min-width: 1200px) {
        border-radius: 8px;
        width: 18%;
        min-width: 250px;
        background: linear-gradient(
                        to bottom,
                        #171717,
                        #171717,
                        #232323,
                        #373737,
                        #595959 40%,
                        $white 40%
        );
      }

      .card-details {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        margin-top: 15px;

        @media (min-width: 1200px) {
          margin-top: 35px;
        }

        .card-icon {
          position: relative;
          top: 1px;
          height: 190px;
          width: 215px;

          img {
            height: 100%;
            width: 100%
          }
        }

        .card-title {
          color: $black;
          font-family: $magistralCondMed;
          font-size: 28px;

          @media (min-width: 1200px) {
            font-size: 38px;
          }
        }

        button {
          font-size: 16px;
          width: 40%;
          background-color: $black;
          border-radius: 8px;
          pointer-events: unset;

          @media (min-width: 890px) {
            width: 60%;
          }

          @media (min-width: 1200px) {
            font-size: 20px;
            width: 70%;
          }

          a {
            font-family: $magistralCondMed;
            color: $white;
            text-decoration: none;
          }

          &:hover {
            background-color: $black;
          }
        }
      }

      .restricted {
        color: $black;
        font-family: $korolevMed;
        font-size: 16px;
        padding: 6px 0;
        width: 40%;

        @media (min-width: 890px) {
          width: 60%;
        }

        @media (min-width: 1200px) {
          font-size: 20px;
          width: 70%;
        }

      }
    }

    .enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
