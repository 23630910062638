@import "styles/variables";

.projects {
  &__sidebar {
    padding: 35px 25px 25px;

    &-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 152px;
      width: 100%;
    }

    &-container {
      margin-bottom: 35px;
    }

    &-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 25px 0;
      border-bottom: 1px solid $lightGray50;

      & p {
        font-family: $magistralCondMed;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
      }

      & :first-child {
        width: 157px;
      }

      & :nth-child(2) {
        width: calc(100% - 157px);
        text-align: center;
        word-wrap: break-word;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 75%;
    padding: 30px;

    &-container {
      width: 100%;
      height: calc(100vh - 246px);
      border: $accountingBorder;
      border-radius: $borderRadius;
      overflow: auto;
    }
  }

  &__dropbox {
    &-files {
      position: relative;
      height: 100%;
      width: 70%;

      &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-family: $magistralCondMed;
        font-size: 25px;
        padding: 20px;
      }

      &-container {
        height: calc(100% - 170px);
        overflow: auto;
        margin-bottom: 20px;
      }

      &-list {
        display: flex;
        flex-flow: row wrap;
        gap: 20px 40px;
        padding: 30px 20px;
        align-items: flex-start;
      }

      &-file {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      & .create-button {
        background-color: $highlightNavy;
        border-color: $lightGray50;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  &__tasks {
    &-task {
      &--selected {
        background: linear-gradient(to right, rgba(20, 26, 102, 0.92), rgba(239, 239, 239, 0));
      }
    }
  }

  &__row {
    border-bottom: 1px solid #efefef80;
    position: relative;

    &--withoutBorder {
      border-bottom: none;
    }

    &--selected {
      & > td {
        position: relative;
        z-index: 2;


        &:first-child:after {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          width: calc(75vw - 70px);
          height: 100%;
          content: "";
          background-image: linear-gradient(to right, rgba(20, 26, 102, 0.92), rgba(239, 239, 239, 0));
        }
      }
    }
  }

  &-page .create-button {
    background-color: $highlightNavy;

    &:hover {
      background-color: transparent;
    }
  }

  &__analytics {
    &-statusContainer {
      display: flex;
      justify-content: space-between;
    }

    &-selects {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
    }

    &-select {
      width: 50%;
    }

    &-number {
      color: $projectsAqua;
    }

    &-error {
      color: $cancelRed;
    }

    &-loading {
      height: 576px;

      @media (min-width: 962px) {
        height: 278px;
      }

      @media (min-width: 1680px) {
        height: 129px;
      }
    }
  }
}