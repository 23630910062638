@import "src/styles/variables";

.dialog-title,
.dialog-title-blue,
.dialog-title-navy,
.dialog-title-green {
  font-family: $magistralCondMed;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  padding-left: 24px;
  text-transform: uppercase;
  width: 100%;
}

.dialog-spinner {
  position: absolute;
  bottom: 5px;
  right: 45px;

  & span {
    margin: 0;
  }
}

.dialog-title {
  color: $black;
}

.dialog-title-blue {
  color: $primaryBlue;
}

.dialog-title-navy {
  color: $black;
}

.dialog-title-green {
  color: $primaryGreen;
}

.gray {
  color: $white;
}

.dialog-title-highlight,
.dialog-title-highlight-blue,
.dialog-title-highlight-navy,
.dialog-title-highlight-green {
  font-family: $magistralCondMed;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
}

.dialog-title-highlight {
  color: $primaryGreen;
}

.dialog-title-highlight-blue {
  color: $highlightBlue;
}

.dialog-title-highlight-green {
  color: $highlightGreen;
}

.dialog-title-highlight-navy {
  color: $highlightNavy;
}

.mass-import-label {
  color: $primaryGreen;
  font-family: $magistralCondMed;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.form-row-label {
  position: relative;
  color: $primaryGreen;
  font-family: $magistralCondMed;
  font-style: normal;
  font-weight: 500;
  margin: 10px 0 5px;
  text-align: left;
  text-transform: uppercase;
}

.form-row-spinner {
  position: absolute;
  top: -7px;
  left: 455px;
  z-index: 1;
}

.add-user-form-inputs {
  background-color: $white;
}

.add-user-error {
  color: #B84A32;
  font-family: $korolevMed;
  font-size: 14px;
  margin-top: 15px;
}

.add-user-spinner {
  position: absolute;
  left: 340px;
  bottom: 0;
  z-index: 1;

  &-placement {
    position: absolute;
    left: 160px;
    bottom: 0;
    z-index: 1;
  }
}

.file-error {
  color: $primaryGreen;
  font-family: $korolevMed;
  font-size: 14px;
}

.belles-file-upload {
  align-items: center;
  justify-content: center;
  margin: 16px 0;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.new-modal {

  &__title {
    width: 100%;
    color: $black;
    font-family: $magistralCondMed;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    text-transform: uppercase;
    border-bottom: solid 2px $black;
    padding: 0 3px;

    &--green {
      color: $primaryGreen;
    }

    &--blue {
      color: $primaryBlue;
    }

    &--purple {
      color: $primaryPurple;
    }

    &--dark{
      color:$black; 
      border-bottom: solid 2px $black;
    }
  }
}

.rich-editor .quill {
  
  width: 100%;
  border: 1px solid #a5af96;

  & .ql-container, .ql-editor{
    min-height: 300px;
  }

  & .ql-container{
    border: none;
  }
  & .ql-toolbar{
    border: none;
    border-bottom: 1px solid #a5af96;
  }
}

.korolev-medium-font-form{
  & input, textarea, select {
    font-family: $korolevMed;
  }
}

.content-container.content-dark-color {

  & .input-small-bot-margin {
    margin-bottom: 10px;
  }

  & .sale-form-error {
    top:16px;
  }

  *:not(.react-datepicker-popper *) {
    text-align: left;
  }

  & .sale-form-spinner span {
    margin: 0;
    padding: 0;
  }

  & .file-row {
    & div{
      color: #2A3D1F;
      font-family: $magistralCondMed;
      font-style: normal;
      font-weight: 500;
    }
  }

  & input, select, textarea {
    color:$black;
    background-color: #fff;
    border: 1px solid #a5af96;
    border-radius: 8px;
  }
  & input, select{
    height: 45px;
  }
  
  & ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b5b5b5;
    opacity: 1; /* Firefox */
  }
  
  & ::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #b5b5b5;
  }
  
  & ::-ms-input-placeholder { /* Microsoft Edge */
    color: #b5b5b5;
  }

  &.navy-color-palette{

    & .file-row {
      &>span{
        color:$highlightNavy;
      }
    }

    & button:not(.react-datepicker-popper button){
      background-color:$highlightNavy;
      :hover {
        background-color:$highlightNavy;
      }
    }
  }

  &.purple-color-palette{

    & .file-row {
      &>span{
        color:$highlightPurple;
      }
    }

    & button:not(.react-datepicker-popper button){
      background-color:$highlightPurple;
      :hover {
        background-color:$highlightPurple;
      }
    }
  }
}

.delete-modal {
  &-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 0 20px;

    & > p {
      font-size: 20px;
      font-family: $korolevMed;
    }

    & span {
      text-decoration: underline;
      text-transform: capitalize;
      font-family: $korolevMed;
    }
  }

  &-buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    width: 100%;
  }

  &-error {
    position: absolute;
    bottom: -30px;
    font-size: 14px;
    font-family: $korolevMed;
    color: $cancelRed;
  }
}

.form-public-msg {
  color: #556155;
  font-family: $korolevMed;
  font-size: 12px;
}