@import "styles/variables";

.task-modal {
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: $black;

  &__tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 2px solid $highlightNavy;
  }

  &__tab {
    display: flex;
    padding: 10px 28px 5px;
    background-color: transparent;
    border: none;
    border-radius: 20px 20px 0 0;
    color: $black;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    transition: all 0.3s;

    &:hover {
      background-color: $lightGray75;
    }

    &--active {
      color: $white;
      background-color: $highlightNavy;

      &:hover {
        background-color: $highlightNavy;
      }
    }
  }

  &__content {
    height: 330px;
    width: 450px;
  }

  &__textarea {
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: transparent;
    color: $white;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 600;
    resize: none;
    cursor: default;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
  }

  &__radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    background-color: transparent;
    border: none;

    &-border {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      height: 18px;
      width: 18px;
      border: 1px $black solid;
      border-radius: 50%;

      &--active {
        width: 12px;
        height: 12px;
        background-color: $highlightNavy;
        border-radius: 50%;
      }
    }

    &-name {
      color: $black;
      letter-spacing: 1px;
      font-size: 16px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;

    &-field {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    & input {
      color: $black;
      background-color: #fff;
      border: 1px solid $lightGray50;
    }

    & fieldset {
      border: 1px solid $lightGray50;
    }

    & .react-datepicker__input-container {
      display: flex;
    }
  }

  &__toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    color: $black;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 600;
  }

  &__submit {
    position: absolute;
    bottom: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: flex-end;
    width: 100px;
    height: 40px;
    padding: 0;
    margin: -20px 0;
    color: $white;
    font-family: $korolevMed;
    font-size: 16px;
    line-height: 40px;
    background-color: $highlightNavy;
    border-radius: 10px;
    transition: 0.3s;
    border: none;

    &:hover {
      background-color: $midGray;
    }
  }

  &__status {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px 15px;
    margin-bottom: 20px;
  }
}

.purple-button {
  background-color: $highlightNavy;
  color: white;
  border: 1px solid white;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 10px;
}

.navy-button {
  background-color: $highlightNavy;
  color: $white;
  border: 1px solid $lightGray25;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s;

  &:hover {
    background-color: transparent;
  }
}

