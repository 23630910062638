@import "../styles/variables";

.clear-button {
  color: $white;
  cursor: pointer;
  font-family: $korolevMed;
  font-size: 0.8rem;
  margin-bottom: 20px;
  margin-top: 8px;
  padding-right: 6px;
  text-align: right;
  &:hover {
    color: $highlightGreen;
  }
}

.options-container {
  position: relative;
  background-color: transparent;
  border: $accountingBorder;
  border-radius: 10px;
}

.options-header {
  display: flex;
  border-bottom: $accountingBorder;
}

.options-header-single-option {
  border-bottom: 5px solid $white;
  color: $white;
  font-family: $magistralCondMed;
  font-size: 20px;
  line-height: 27px;
  padding: 15px;
  width: 100%;
}

.options-header-button,
.options-header-button-selected {
  background-color: transparent;
  border: 0;
  border-bottom: 5px solid transparent;
  color: $white;
  font-family: $magistralCondMed;
  font-size: 20px;
  line-height: 27px;
  padding: 15px;
  width: 50%;
}

.options-header-button {
  &:hover {
    background-color: $lightGray25;
  }
}

.options-header-button-selected {
  border-bottom: 5px solid $white;
}

.left {
  border-top-left-radius: 10px;
}

.right {
  border-top-right-radius: 10px;
}

.category-button,
.category-button-selected,
.filter-button,
.filter-button-selected {
  align-items: center;
  border: 0;
  border-bottom: $accountingBorder;
  color: $white;
  display: flex;
  font-family: $korolevMed;
  font-size: 15px;
  padding: 20px;
  text-align: left;
  width: 100%;
  &:hover {
    background-color: $lightGray25;
  }
}

.category-button,
.filter-button {
  background-color: transparent;
}

.category-button-selected,
.filter-button-selected {
  background: linear-gradient(to right, $highlightGreen, #efefef00);
}

.filter-button,
.filter-button-selected {
  padding-left: 40px;
}

.dropdown {
  font-size: 18px;
  padding-left: 20px;
}

.last-button {
  border-bottom: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.arrow-icon {
  font-size: 1rem !important;
  margin-left: auto;
  padding-left: 8px;
  &:hover {
    color: $highlightGreen;
  }
}

.submit {
  background-color: $white;
  border: 0;
  color: $highlightGreen;
  font-family: $magistralCondMed;
  font-size: 20px;
  line-height: 20px;
  padding: 20px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;

  &:hover {
    background-color: $white;
    color: $primaryGreen;
  }
}

.filter {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  margin: 25px;
  border: $accountingBorder;
  border-radius: 10px;
  overflow: hidden;

  & :last-child {
    border: 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    border-bottom: $accountingBorder;
  }

  &__option {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 60px;
    background-color: transparent;
    color: $white;
    font-family: $magistralCondMed;
    font-size: 20px;
    border: none;

    &--active::after {
      position: absolute;
      bottom: 0;
      content: '';
      height: 1px;
      width: 100%;
      border-bottom: 5px solid $projectsAqua;
    }
  }

  &__button {
    background-color: transparent;
    border: 0;
    border-bottom: $accountingBorder;

    font-family: $korolevMed;
    color: $white;
    font-size: 15px;
    min-height: 48px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 18px 6px 30px;
    transition: all 0.3s;

    &:hover {
      background-color: $lightGray25;
    }

    &:active {
      background-color: $lightGray50;
    }

    &--main {
      padding: 8px 18px;
      min-height: 52px;
      height: fit-content;

      & span {
        font-family: $korolevMed;
        font-size: 18px;
        line-height: 24px;
        text-align: start;
      }
    }

    & i {
      font-size: 1rem !important;
    }

    &--active {
      background: linear-gradient(to right, rgba(20, 26, 102, 0.92), #efefef00);
    }
  }
}
