@import "styles/variables";

.sales {
  color: #0fbcf9;

  &__active {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #efefef0a;
    border: 1px solid #efefef80;
    border-radius: 10px;
    padding-bottom: 20px;

    &-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 10px;
    }

    &-category {
      text-transform: uppercase;
      color: #A4F2F1;
      font-family: $magistralCondMed;
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 20px 0 20px;
      flex-grow: 1;
      border-radius: 8px;
      min-height: 40px;
    }

    &-listItem {
      display: flex;
      gap: 7px;
      align-items: center;
    }
  }

  &__tagType {
    &-item {
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      cursor: pointer;
      border: 1px solid rgba(239, 239, 239, 0.5019607843);
    }
  }

  &__tagGroup {
    &-item {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__head-button {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    width: 150px;
  }

  &__dollar_sign {
    font-family: "MagistralCondW08-Medium", sans-serif;
  }
  
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;
  }

  &__quote {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    border: $accountingBorder;
    border-radius: 10px;

    &--left {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &--right {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &--container {
      overflow: auto;
      transition: all 0.3s;

      &--hidden {
        opacity: 0;
      }

      &--full {
        height: calc(100vh - 380px);
      }
    }

    &--create {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      justify-content: space-between;
      width: calc(100% + 40px);
      height: 48px;
      margin: 0 -20px 10px;
      padding: 0 20px 10px;
      border-bottom: $accountingBorder;
    }

    &-remove {
      display: flex;
      flex-direction: column;
    }

    &-active {
      display: flex;
      flex-direction: column;
    }

    &-total {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 20px;

      & > div {
        display: grid;
        gap: 4px;
        grid-template-columns: 32% 32% 32%;

        & > p {
          text-align: center;
        }
      }
    }

    &--row {
      display: grid;
      grid-template-columns: 50% 25% 25%;
      gap: 10px;
      padding: 10px;
      color: $white;
      font-size: 16px;
      text-align: start;
      background-color: $lightGray10;
      border: none;
      border-radius: 5px;
      align-items: center;

      &:hover {
        background-color: $lightGray25;
      }

      &-header {
        background-color: transparent;
        padding: 0 10px;
        &:hover {
          background-color: transparent;
        }
      }

      & p {
        text-align: center;

        &:first-child {
          text-align: start;
        }
      }

      &--extended {
        gap: 5px;
        grid-template-columns: 1fr 65px 61px 81px 63px 48px;

        @media (min-width: 1600px) {
          grid-template-columns: 1fr 65px 69px 98px 64px 89px;
        }

        & p {
          max-width: 100%;
          word-break: break-all;
        }
      }
    }

    &--row--active {
      display: grid;
      grid-template-columns: 50% 25% 25%;
      gap: 10px;
      padding: 10px;
      color: $white;
      font-size: 16px;
      text-align: start;
      background-color: $lightGray10;
      border: none;
      border-radius: 5px;
      align-items: center;

      &:hover {
        background-color: $lightGray25;
      }

      &-header {
        display: grid;
        gap: 5px;
        grid-template-columns: 1fr 65px 61px 81px 63px 55px 37px;
        background-color: transparent;
        font-size: 12px;
        &:hover {
          background-color: transparent;
        }


        @media (min-width: 1600px) {
          grid-template-columns: 1fr 65px 69px 98px 64px 89px 37px;
        }
      }
    }

    &__header {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &-container {
        display: flex;
        background: linear-gradient(to right, rgba(20, 26, 102, 0.92), rgba(239, 239, 239, 0));
        padding: 10px 20px;
        justify-content: space-between;
        border-radius: 10px 10px 0 0;
        min-height: 60px;
      }
    }

    &--title {
      display: flex;
      text-align: center;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      font-family: $korolevMed;
      font-size: 16px;

      & > p {
        font-family: $korolevMed;
        font-size: 16px;
      }
    }

    &--form {
      position: relative;
      overflow: hidden;
      transition: all 0.5s;

      &--hidden {
        opacity: 0;
      }

      &-error {
        position: absolute;
        color: #a20601;
        font-size: 12px;
      }
    }

    &--field {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      justify-content: space-between;

      &-start {
        justify-content: inherit;
      }

      &-column {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
      }

      & textarea {
        border-radius: 0;
      }
    }

    &--info {
      background-color: #fff;
      color: #000000DE;
      font-family: "Roboto","Helvetica","Arial",sans-serif;
      font-size: 16px;
      padding: 8px 10px;
      border-radius: 10px;
      width: 120px;

      &--placeholder {
        color: #bdbcbc;
      }
    }

    &--modal {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1px;
      width: 500px;
      min-height: 200px;
    }

    &--add {
      background-color: transparent;
      border: none;
      color: $highlightNavy;
      transition: all 0.3s;
      margin: 8px 0 0;
      padding: 0;
      font-family: $korolevMed;
      font-size: 16px;

      &:hover {
        color: $primaryNavy;
      }
    }

    &--error {
      text-align: center;
      line-height: 180px;
      font-family: $korolevMed;
      font-size: 16px;
      color: $cancelRed;
      width: 100%;
    }

    &--category {
      font-family: $korolevMed;
      font-size: 16px;
      color: $black;
      text-align: start;
      padding-left: 10px;

      &-template {
        margin: 20px 0 40px;
        padding: 0;
        text-align: center;
        width: 100%;
      }

      &-row {
        position: relative;
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        height: 45px;
        width: 100%;
      }
    }

    &--box {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 100%;

      &-error {
        position: absolute;
        top: -14px;
        right: 54px;
        color: $cancelRed;
        font-family: $korolevMed;
        font-size: 14px;
      }
    }
  }

  &__milestone {
    display: flex;
    flex-direction: row;
    border: $accountingBorder;
    border-radius: $borderRadius;

    &__side-bar {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 300px;
      height: 100%;
      padding: 30px 0;
      border-right: $accountingBorder;
    }

    &__tasks {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &__stage {
      display: flex;
      flex-direction: row;
      gap: 15px;
      color: $white;
      width: 100%;
      font-size: 20px;
      font-weight: 600;
      text-align: start;
      text-transform: capitalize;
      background-color: transparent;
      border: none;
      padding: 10px 15px;
      border-left: 11px solid transparent;
    }

    &__task {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 18px;
      color: $white;
      width: 100%;
      font-size: 15px;
      text-align: start;
      background-color: transparent;
      border: none;
      padding: 10px 17px;
      border-left: 11px solid transparent;

      &-description {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;
        width: calc(100% - 300px);
        min-height: 100%;
        padding: 30px 20px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 20px;

        & h2 {
          font-size: 30px;
        }

        & p {
          font-size: 18px;
        }
      }
    }

    &--active {
      background: linear-gradient(to right, #141a66, rgba(239, 239, 239, 0));
      border-left: 11px solid #A4F2F1;
    }

    & p.sales__milestone__stage {
      border-left: none;
      padding-left: 25px;
    }

    &__check {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 20px;
      height: 20px;
      background-color: $white;
      border-radius: 50%;

      &--big {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 25px;
        height: 25px;
        background-color: $white;
        border-radius: 50%;
      }
    }

    &__box {
      display: flex;
      min-width: 14px;
      height: 14px;
      background-color: $highlightNavy;
      border-radius: 50%;

      &--big {
        display: flex;
        min-width: 19px;
        height: 19px;
        background-color: $highlightNavy;
        border-radius: 50%;
      }
    }
  }

  &__sale {
    &-expand {
      display: flex;
      flex-direction: row;
      gap: 40px;
      justify-content: space-between;
      width: 100%;
      padding: 0 10px;

      &-content {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: flex-start;
        width: 100%;
        padding-left: 5px;
      }

      &-field {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;

        &-title {
          font-size: 16px;
          font-weight: 800;
          color: $lightGray50;
        }
      }
    }
  }

  &__manage {
    display: flex;
    flex-direction: row;

    &--left {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-bottom: 0;
    }

    &--top {
      display: flex;
      flex-flow: row wrap;
      padding: 10px 10px 10px 20px;
      border: $accountingBorder;
      border-radius: 10px;

      & .create-button {
        border: 1px solid $lightGray50;
        background-color: $highlightNavy;
        margin: -35px 2.65% 5px 0;

        &:hover {
          border: 1px solid $highlightNavy;
          color: $highlightNavy;
          background-color: $lightGray50;
        }

        &--dark {
          &:hover {
            color: $white;
            border: 1px solid $lightGray50;
            background-color: transparent;
          }
        }
      }

      & .sale-form-error {
        top: 68px;
      }

      & .sale-form-input {
        height: 39px;

        &-textarea {
          height: 95px;
        }
      }
    }

    &--bottom {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: calc((75vw - 92px) / 2);
      height: calc(100vh - 714px);
      min-height: 196px;
      padding: 10px 20px;
      border: $accountingBorder;
      border-radius: 10px;
      overflow: auto;
    }

    &--right {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: calc((75vw - 92px) / 2);
      height: 100%;
      padding: 10px 20px;
      border: $accountingBorder;
      border-radius: 10px;
      overflow: auto;
    }

    &--head {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: flex-start;
      padding: 0 10px 8px;
      color: $white;
      font-size: 16px;
      text-align: start;
      border: none;
      border-radius: 5px;

      & :nth-child(1) {
        flex-basis: 30%;
      }

      & :nth-child(2) {
        flex-basis: 30%;
      }

      & :nth-child(3) {
        flex-basis: 40%;
      }
    }

    &--container {
      display: flex;
      flex-direction: column;
      gap: 2px;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &--row {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: flex-start;
      width: calc((75vw - 172px) / 2);
      padding: 10px;
      color: $white;
      font-size: 16px;
      text-align: start;
      background-color: $lightGray10;
      border: none;
      border-radius: 5px;

      &:hover {
        background-color: $lightGray25;
      }

      & :nth-child(1) {
        flex-basis: 30%;
      }

      & :nth-child(2) {
        flex-basis: 30%;
      }

      & :nth-child(3) {
        flex-basis: 40%;
        max-width: 40%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &-button {
      background: transparent;
      border: 0;
      color: $white;
      display: block;
      font-family: $magistralCondMed;
      font-size: 25px;
      line-height: 34px;
      margin-bottom: 10px;
    }
  }
  &-division {
    position: relative;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
    border: $accountingBorder;

    &:hover {
      position: relative;
      border: 1px solid $lightGray75;

      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 2;
        box-sizing: border-box;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 2px solid $lightGray75;
        border-radius: 10px;
      }
    }

    &-color {
      position: absolute;
      width: 25px;
      height: 100%;
      left: 0;
      top: 0;

      &-container {
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        margin-top: 25px;

        & p {
          font-family: $magistralCondMed;
          color: $white;
        }
      }

      &-input {
        background-color: transparent;
        padding: 2px;
        border-radius: 5px;
        border: 1px solid $white;
        height: 30px;
      }
    }
  }
}

.service-buttons {
  & .create-button {
    border: 1px solid $lightGray50;
    background-color: $highlightNavy;

    &:hover {
      border: 1px solid $highlightNavy;
      color: $highlightNavy;
      background-color: $lightGray50;
    }

    &--dark {
      &:hover {
        color: $white;
        border: 1px solid $lightGray50;
        background-color: transparent;
      }
    }
  }
}

.sale-form {
  &-date {
    position: absolute;
    top: 6px;
    right: 0;
    color: $white;
    font-size: 16px;
    font-family: $magistralCondMed;

    & span {
      font-family: $magistralCondMed;
    }

    &-black {
      color: $black;
    }

    &-white {
      color: $white;
    }

    &-red {
      color: $cancelRed;
    }

    &-green {
      color: $submitGreen;
    }

    &-yellow {
      color: $pendingOrange;
    }
  }

  &-section {
    margin-bottom: 35px;

    &-content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      & .create-button {
        border: 1px solid $lightGray50;
        background-color: $highlightNavy;
        margin-right: 2.65%;

        &:hover {
          border: 1px solid $highlightNavy;
          color: $highlightNavy;
          background-color: $lightGray50;
        }

        &--dark {
          &:hover {
            color: $white;
            border: 1px solid $lightGray50;
            background-color: transparent;
          }
        }
      }
    }
  }

  &-set {
    position: relative;
    margin-bottom: 30px;
    width: 33%;
    text-align: left;

    &-half {
      position: relative;
      margin-bottom: 30px;
      width: 50%;
    }

    &-full {
      position: relative;
      margin-bottom: 30px;
      width: 100%;
    }

    &-row {
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
      width: 50%;
      height: fit-content;
    }
  }

  &-label {
    color: $white;
    font-family: $korolevMed;
    font-size: 15px;
    margin-bottom: 8px;

    &-row {
      margin-bottom: 2px;
    }
  }

  &-error {
    position: absolute;
    top: 8px;
    right: 5%;
    color: $cancelRed;
    font-size: 12px;
    font-family: $korolevMed;
  }

  &-input {
    background-color: $lightGray25;
    border: 1px solid $white;
    border-radius: 6px;
    color: $white;
    font-family: $korolevMed;
    font-size: 15px;
    padding: 8px 12px;
    width: 95%;

    &-textarea {
      height: 75px;
      resize: none;
      width: 97.35%;
    }

    &:focus-visible {
      border: 1px solid transparent;
    }

    &:focus {
      border: 1px solid transparent;
    }
  }

  &-input-email {
    background-color: $lightGray25;
    border: 1px solid $white;
    border-radius: 6px;
    color: $white;
    font-family: $korolevMed;
    font-size: 15px;
    padding: 8px 12px;
    width: 98.5%;
  }

  &-input::placeholder,
  &-input-email::placeholder {
    color: $lightGray50;
  }

  &-button-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    width: 100%;

    & .sale-form-error {
      top: -17px;
      right: 3%;
    }
  }

  &-spinner {
    position: absolute;
    right: 119px;
    top: -38px;

    &-edit  {
      top: 0;
      right: 155px;
    }

    &-milestone {
      position: absolute;
      top: -73px;
      left: 0;
    }

    &-quote {
      position: absolute;
      bottom: -25px;
      right: 200px;
    }
  }

  &-competitor {
    position: absolute;
    bottom: -40px;
    margin: 0;

    & p {
      margin: 0;
    }
  }
}

.sale-filetype {
  align-items: flex-start !important;
  display: flex !important;
  width: 100%;
  justify-content: flex-start !important;
  & span{
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-family: $korolevMed;
    font-size: 1rem;
  }
  & .recent-file-name{
    color: $projectsAqua;
  }
}

.sale-filetype-selected {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}

.sale-filetype-typography {
  color:white ;
  text-align: left;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-family: $korolevMed;
  font-size: 1rem;
  text-transform: capitalize;
}

.content-dark-color {
  & button:hover{
    background-color: $highlightNavy;
  }
  
  & input, select, textarea {
    color:$black;
    background-color: #fff;
    border: 1px solid #a5af96;
    border-radius: 8px;
  }
  & input, select{
    height: 45px;

    &:hover {
      border: 1px solid $highlightNavy;
    }

    &:active {
      border: 1px solid $highlightBlue;
    }
  }
  
  & ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b5b5b5;
    opacity: 1; /* Firefox */
  }
  
  & ::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #b5b5b5;
  }
  
  & ::-ms-input-placeholder { /* Microsoft Edge */
    color: #b5b5b5;
  }
}

.scrollable-table {

  & .table__object-face {
    align-items: unset;
  }

  & .table__header {
    min-width: fit-content;
    height: 56px;
  }
  
  & .table__header>div,
  .table__object-face>div {
    min-width: 200px;
    padding: 20px;
  }

  & .table__object, .table__object-face {
    position: relative;
  }

  & .table__object-panel {
    position: sticky;
    left: 0;
    width: 500px;
    margin-bottom: 0;
    z-index: 1; 
  }
 
  & .table__object-face>div:nth-child(1), .table__header>div:first-child {
    background: linear-gradient(90deg, rgb(6, 15, 35) 0%, rgba(7, 19, 40, 1) 64%, rgba(3, 19, 44, 0.8) 82%, rgba(1, 16, 42, 0.08) 100%);
    position: sticky;
    flex: 1;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1; 
  }

  & .table__object {
    min-width: fit-content;
  }

  & .sales__sale-expand {
    position: relative;
    min-height: 70px;
    display: flex;
    align-items: center;

    &:after {
      background: linear-gradient(90deg, rgb(6, 15, 35) 0%, rgba(7, 19, 40, 1) 64%, rgba(3, 19, 44, 0.8) 82%, rgba(1, 16, 42, 0.08) 100%);
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 200px;
    }
    
    & button span {
      display: none;
    }

    & .table-button {
      position: absolute;
      z-index: 2;
    }

    & .table-button :first-child {
      left:50px;
    }

    & .table-button:last-child {
      left:170px;  
    }
  } 

}

.document-icon-btn {
  display: flex;
  align-items: center;

  & img {
    width: 25px;
    transition: 0.3s;
  }

  &.active {
    & img {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  } 
  
  &.disabled {
    opacity: 0.2;
  } 
}

.manage {
  &__group {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 0 0 15px;
  }

  &__error {
    position: absolute;
    top: 100px;
    font-family: $korolevMed;
    font-size: 14px;
    color: $cancelRed;
  }
}
