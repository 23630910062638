.spinner {
  &-fullScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 260px);
  }

  &-fullHeight {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  &-analytics {
    position: absolute;
    top: 0;
    height: calc(100% - 80px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}