@use "../styles/variables";

.metric {

  &__number-blue {
    color: variables.$highlightBlue;
    font-family: variables.$magistralCondMed;
    font-size: 28px;
    margin-left: 42px;
  }

  &__med-number-blue {
    color: variables.$highlightBlue;
    font-family: variables.$magistralCondMed;
    font-size: 38px;
  }


  &__large-number-blue {
    color: variables.$highlightBlue;
    font-family: variables.$magistralCondMed;
    font-size: 70px;
  }
}