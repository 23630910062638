@use "../styles/variables";

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 90%;
  gap: 50px;
  margin-left: 5%;

  .spreadsheet {
    display: flex;
    flex-direction: column;
    border-radius: variables.$borderRadius;
    width: 100%;

    .ss-headers {
      display: flex;
      justify-content: space-evenly;
      border-bottom: 2px solid black;
      width: 90% !important;

      td {
        background-color: variables.$midGray;
        padding: 10px;
        color: variables.$black;
        width: 100%;
        border-right: 2px solid black;
      }

      .last-item {
        border: 0px solid black;
      }
    }

    .ss-content {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      text-align: left;
      background-color: #171717;
      border: 2px solid variables.$midGray;
      width: 90% !important;

      td {
        padding: 10px;
        width: 100%;
        border-right: 2px solid variables.$midGray;
      }

      .first-row {
        border-top: 0px solid black;
      }
    }
  }

  .header-left {
    border-top-left-radius: 10px;
  }

  .header-right {
    border-top-right-radius: 10px;
  }

  .last-row {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .last-item {
      border-bottom-right-radius: 5px;
    }
  }

  .last-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid transparent;
    transition: 0.2s ease-in-out;
  }

  .pass {
    border-left: 2px solid #60992d;
  }

  .fail {
    border-left: 2px solid #a20601;
  }
  .pending {
    border-left: 2px solid #fff570;
  }
  .checkbox {
    margin: 0;
  }

  .disabled {
    color: variables.$midGray !important;
    pointer-events: none;
    opacity: 0.3;
  }
  .edit-btn {
    &:hover {
      opacity: 1;
      color: #a20601;
    }
  }
}
