@use "../styles/variables";

// All Contracts

.contract-totals-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.applicants-hired-per-header {
  display: flex;
  align-items: center;
  font-family: variables.$magistralCondMed;
  font-size: 25px;
  margin-bottom: 20px;
  width: 100%;
}

.applicants-hired-per-interval {
  margin-left: 12px;
  width: 20%;
}

.applicants-hired-per-number {
  color: variables.$highlightBlue;
  font-family: variables.$magistralCondMed;
  font-size: 40px;

  &--green {
    color: variables.$highlightGreen;
  }
}

.hiring-matrix-container {
  display: flex;
  align-items: center;
}

.matrix-total-container {
  border: variables.$accountingBorder;
  border-radius: variables.$borderRadius;
  margin-right: 20px;
  padding: 12px;
  text-align: center;
  width: 15%;
}

.matrix-breakdown-container {
  width: 80%;
}

.total-applicants-number {
  color: variables.$highlightBlue;
  font-family: variables.$magistralCondMed;
  font-size: 40px;
  line-height: 60px;

  &--green {
    color: variables.$highlightGreen;
  }
}

.total-applicants-text {
  color: variables.$white;
  font-family: variables.$korolevMed;
  font-size: 16px;
}

.deep-dive-header {
  display: flex;
  gap: 20px;
  align-items: center;
  font-family: variables.$magistralCondMed;
  font-size: 25px;
  margin-bottom: 20px;
}

.deep-dive-select {
  width: 40%;
}


// Recruiter Stats

.analytics-back-button {
  cursor: pointer;
  margin-left: 0;
  margin-right: 8px;
}

.recruiter-stats-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.analytics-codes {
  position: absolute;
  top: -24px;
  left: 0;
  z-index: 0;
  width: 100%;
  background-color: variables.$lightGray75;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  cursor: default;
}


.stats-group-container {
  margin-bottom: 30px;
  width: 100%;
}

.stats-group-header {
  display: flex;
  align-items: center;
  font-family: variables.$magistralCondMed;
  font-size: 25px;
  margin-bottom: 16px;
  width: 100%;
}

.stats-group-subheader {
  display: flex;
  align-items: center;
  font-family: variables.$korolevMed;
  font-size: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.stats-group-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.stage-stat-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: variables.$lightGray25;
  border-radius: variables.$borderRadius;
  min-width: 285px;
  padding: 20px;
  text-align: center;
  width: 15%;
}

.stage-stat-header {
  color: variables.$white;
  font-family: variables.$korolevMed; 
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
}

.stage-stat-number {
  color: variables.$highlightBlue;
  font-family: variables.$magistralCondMed;
  font-size: 35px;
  margin-bottom: 4px;

  &--green {
    color: variables.$highlightGreen;
  }
}

.stage-stat-unit {
  font-family: variables.$korolevMed;
  font-size: 13px;
}

.fill-stat-container {
  margin-bottom: 16px;
}

.error-message {
  color: variables.$cancelRed;
  font-family: variables.$magistralCondMed;
  font-size: 16px;
}
