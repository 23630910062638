.css-wp8hdb-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  top: -10px;
  left: -10px;
}

.css-1yn3ryl-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  top: -10px;
  left: -10px;
}

.css-1yn3ryl-MuiFormLabel-root-MuiInputLabel-root {
  opacity: 0;
}
