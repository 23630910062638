@import 'src/styles/variables';


.recruitment-flow {
  background-image: linear-gradient($primaryGreen, $black);
  min-height: 100vh;
}

.filter-header {
  color: $white;
  font-family: $magistralCondMed;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 20px;
  padding-top: 20px;
}

.applicant-table-header {
  color: $primaryGreen;
  font-family: $magistralCondMed;
  text-transform: uppercase;
}

.stage-indicator {
  text-align: center;
  font-family: $korolevMed;
  text-decoration: underline;
}

.step-indicator {
  font-family: $korolevMed;
}

.profile-user-icon {
  height: auto;
  margin-right: 30px;
  width: 95px; 
}

.profile-key {
  color: $lightGray50;
  font-family: $magistralCondMed;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
}

.profile-icon {
  height: auto;
  margin-left: 5px;
  width: 16px;

  &--messages {
    height: auto;
    width: 18px;
  }
}

.profile-key-value {
  color: $white;
  font-family: $korolevMed;
  line-height: 1.5;
  overflow-wrap: break-word;
  text-transform: none;
}

.confirm-date-section {
  margin-right: 8px;
  max-width: 65%;
}

.date-picker {
  text-align: left;
  width: 100%;
  
  input {
    border: 0;
    border-radius: 8px;
    font-family: $korolevMed;
    font-size: 1rem;
    padding: 8px;
    width: 100%;
  }
}
