@import "../styles/variables";

%page {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 80px);
  overflow: auto;
  text-align: left;  
}

.accounting-page {
  @extend %page;
  background-image: linear-gradient($primaryBlue, $black);
}

.projects-page {
  @extend %page;
  background-image: linear-gradient($primaryNavy, $black);
}

.sales-page {
  @extend %page;
  background-image: linear-gradient($primaryNavy, $black);

  & .stage-stat-number {
    color: $projectsAqua;
  }

  &__connection {
    &-button {
      position: absolute;
      top: 11px;
      right: 0;
      height: 50px;
      padding: 0 20px;
      color: $white;
      font-family: "MagistralCondW08-Medium", sans-serif;
      font-size: 18px;
      font-weight: 500;
      background-color: $highlightPurple;
      border: none;
      border-radius: 10px;
      transition: all 0.3s;

      &:hover {
        background-color: #743cd9;
      }
    }
    &-buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
    & .table__header {
      height: 55px;
      margin-top: 15px;
    }
    & .table__data {
      padding-top: 70px;
    }
  }
  
  & .fa-angle-down:hover {
    color: #a4f2f1;
  }

  & .clear-button {
    transition: all 0.3s;
    &:hover {
      color: $projectsAqua;
    }
  }
}

.staffing-page {
  @extend %page;
  background-image: linear-gradient($primaryGreen, $black);
}

.sidebar-content {
  border-right: 1px solid $lightGray50;
  min-width: 300px;
  width: 25%;
}

.page-content {
  padding: 30px 40px 34px 32px;
  width: 75%;
}

.content {
  &-header {
    margin-bottom: 12px;
    text-transform: uppercase;

    &-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  &-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

%content-container,
.content-container {
  border: $accountingBorder;
  border-radius: $borderRadius;
  height: calc(100vh - 206px);
  overflow: auto;
  padding: 29px 22px;
  width: 100%;

  &-tasks {
    height: calc(100vh - 218px);;
  }

  & h3 {
    color: $white;
  }
}

.content-placeholder {
  padding: 40px 0 0;
  font-family: $korolevMed;
  text-align: center;
}

.metrics-content-container {
  border-top: $accountingBorder;
  height: calc(100vh - 206px);
  overflow: auto;
  padding-top: 29px;
  width: 100%;
  opacity: 1;

  &-transparent {
    opacity: 0;
  }

  & .contract-form-section-header {
    padding-left: 20px;
  }

  & .contract-form-section-content {
    position: relative;
    margin: 30px 30px 50px 20px;
    width: calc(100% - 50px);

    & .contracts-table {
      max-height: 600px;
      overflow: auto;
      display: block;

      & tbody {
        width: 100%;
      }
    }
  }
}

.no-content-padding {
  padding: 0;
}

.condensed {
  height: calc(100vh - 230.5px);
}
