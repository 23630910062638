@import "../styles/variables";

body {
  color: white;
}

h1:not(.ql-editor h1),
h2:not(.ql-editor h2),
h3:not(.ql-editor h3),
h4:not(.ql-editor h4),
h5:not(.ql-editor h5),
h6:not(.ql-editor h6){
  font-family: "MagistralCondW08-Medium", sans-serif;
}

h1:not(.ql-editor h1){
  font-size: 40px;
  color: $white;
}

a:not(.ql-editor a){
  display: block;
  text-decoration: none;
}

*:not(.ql-editor *){
  font-family: "Korolev Light", sans-serif;
}

b:not(.ql-editor b){
  font-family: "Korolev Bold", sans-serif;
}

textarea {
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.3);
  color: white;
  outline: solid 0 rgba(255, 255, 255, 0.15);
  transition: outline 0.2s, color 0.2s;
  &:focus {
    outline: solid 4px rgba(255, 255, 255, 0.15);
    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
    transition: all 0.2s;
  }
}

.row {
  display: flex;
  flex-direction: row;
//   align-items: center;
  label {
    margin-right: 10px;
  }
  select {
    margin-right: 10px;
  }
}

.auto {
  width: 100%;
}

.cell {
  padding-left: 10px;
  &:first-of-type {
    padding-left: 0;
    padding-right: 10px;
  }
}

.content {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.page {

}

.sidebar {
  width: 20%;
  flex-shrink: 0;
  flex-grow: 0;
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: lighten($black, 10%);
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
  padding: 20px 0 0 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  position: relative;

  input[type='text'] {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.15);
    border-style: solid;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.2);
    width: 100%;
    font-size: 14px;
    color: white;
    border-radius: 4px;
    &::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }
    &:focus {
        outline: none;
    }
  }

  h1 {
    padding: 0 25px 10px 25px;
  }

  &__pocket-box {
    width: 100%;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
  }

  &__pocket {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 10px 15px 15px 15px;
  }

  &__item {
    padding: 16px 0 16px 25px;
    width: 100%;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: lighten($black, 10%);
    position: relative;
    background: transparent;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    i {
      margin-right: 10px;
      position: relative;
      top: -2px;
    }
    p {
      margin: 0;
    }
    &:hover {
      cursor: pointer;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      &::before {
      }
      &::after {
        width: 7px;
      }
    }

    &:first-of-type {
      border-width: 1px 0 1px 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: transparent;
      margin-right: 20px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.3);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.6);
      transition: all 0.2s;
    }
  }

  &__item-selected {
    padding: 16px 0 16px 25px;
    width: 100%;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: lighten($black, 10%);
    position: relative;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    i {
      margin-right: 10px;
      position: relative;
      top: -2px;
    }
    p {
      margin: 0;
    }

    &:first-of-type {
      border-width: 1px 0 1px 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: transparent;
      margin-right: 20px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.3);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.6);
      transition: all 0.2s;
    }
  }

  &__link {
    @extend .sidebar__item;
    &::before {
      content: "\f105";
      font-family: "Font Awesome 6 Free", sans-serif;
      font-weight: 900;
    }
  }
  &__section {
    padding: 20px;
  }
  &__expand {
    height: 100%;
    flex-shrink: 3;
  }
  &__box {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.3);
    border-radius: $borderRadius;
    overflow: hidden;
    overflow-y: auto;
  }
  &__button {
    width: 100%;
    padding: 16px 0;
    text-align: center;
    background-color: lighten($black, 10%);
    margin: 10px 0 0 0;
    border-radius: $borderRadius;
    user-select: none;
    i {
      display: inline;
      margin-right: 10px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__text-box {
    width: 100%;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    border-style: solid;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.3);
    color: white;
    border-radius: $borderRadius;
    outline: solid 0 rgba(255, 255, 255, 0.15);
    transition: all 0.2s;
    &:focus {
      outline: solid 4px rgba(255, 255, 255, 0.15);
      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    }
    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
      transition: all 0.2s;
    }
  }
  &__profile {
    padding: 20px;
    box-sizing: border-box;
    min-height: 320px;
  }
  &__profile-name {
    span {
      font-size: 12px;
    }
  }
  &__profile-details {
    padding: 30px 0;
    span {
      font-size: 12px;
    }

    .row {
      margin-bottom: 15px;
    }

    img {
      width: 26px;
      height: 26px;
      margin-left: 15px;
      margin-right: 32px;
    }
  }
  &__profile-icon {
    width: 58px;
    margin-right: 15px;
  }

  &-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 231px;

    &-portal {
      height: 410px;
    }

    &-connection {
      height: 283.5px;
    }
  }
}

.table {
  position: relative;
  text-align: left;
  i {
    margin-left: 0;
  }
  p {
    margin: 0;
  }
  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-weight: 800;
    color: $lightGray50;
    border-style: solid;
    border-width: 0 0 2px 0;
    border-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
    user-select: none;
  }
  &__title {
    font-weight: 800;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.3);
  }
  &__business-sales-expanded {
    display: flex;
    flex-direction: row;
    padding: 12px 0;

    &--cell {
      width: 100%;
      padding: 0 10px;
    }
  }
  &__button-transparent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    color: white;
    font-family: "Korolev Light", sans-serif;
    font-size: 16px;
    background-color: transparent;
    border: none;

    &--with-padding {
      height: fit-content;
      padding-top: 16px;
    }
  }
  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 65px;
    align-items: center;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: rgba(255, 255, 255, 0.1);
  }
  &__object {
    position: relative;
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: rgba(255, 255, 255, 0.1);
    &-face {
      display: flex;
      position: relative;
      flex-direction: row;
      flex-wrap: nowrap;
      min-height: 65px;
      align-items: center;
      // background-color: $black;
      z-index: 2;
    }
    &-panel {
      position: relative;
      max-height: 0;
      transition: all 0.3s;
      overflow: auto;
      transition-timing-function: ease-in-out;
      z-index: 0;
      opacity: 0;
    }
    &-panel--open {
      max-height: 800px;
      opacity: 1;
      margin-bottom: 10px;
    }
    &:hover {
      cursor: pointer;
      &-face {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
  &__auto {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__auto-center {
    @extend .table__auto;
    justify-content: center;
  }
  &__auto-spaced {
    @extend .table__auto;
    padding: 0 10px;
    .icon-button {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}

.tabview--small {
  height: 100% !important;
  min-height: 175px;
  max-height: 175px;
  .tabview__content {
    height: calc(100vh - 246px);
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.4);
    border-radius: 0 8px 8px 8px;
  }
  .tabview__tab {
    padding: 0;
    font-size: 14px;
  }
}

.tabview {
  position: relative;
  display: flex;
  flex-direction: column;

  &__buttons {
    display: flex;
    flex-direction: row;
  }
  &__tab {
    width: 30%;
    padding: 12px 15px;
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 10px;
    font-family: "MagistralCondW08-Medium", sans-serif;
    font-size: 20px;
    border-radius: 6px 6px 0 0;
    user-select: none;
    transition: all 0.2s;
    text-align: center;
    border-color: rgba(255, 255, 255, 0);
    border-style: solid;
    border-width: 3px 3px 0 3px;
    color: rgba(180, 180, 180, 1);
    p {
      font-family: "MagistralCondW08-Medium", sans-serif;
    }
    &--selected {
      border-color: rgba(255, 255, 255, 0.2);
      color: white;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__content {
    border-style: solid;
    border-width: 2px;
    height: calc(100vh - 246px);
    border-color: rgba(255, 255, 255, 0.3);
    border-radius: 0 8px 8px 8px;
    overflow: hidden;
    overflow-y: auto;
  }
}

.small-button {
  padding: 4px 8px;
  text-align: center;
  background-color: lighten($black, 10%);
  margin: 10px 0 0 0;
  border-radius: $borderRadius;
  user-select: none;
  transition: all 0.2s;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.15);
  &:first-of-type {
    margin: 0;
  }
  i {
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    cursor: pointer;
  }
}

.big-button {
  color: white;
  width: 100%;
  padding: 16px 0;
  text-align: center;
  background-color: lighten($black, 10%);
  margin: 10px 0 0 0;
  border-radius: $borderRadius;
  border: 0;
  user-select: none;
  transition: all 0.2s;
  &:first-of-type {
    margin: 0;
  }
  i {
    display: inline;
    margin-right: 10px;
  }
  &:hover {
    cursor: pointer;
  }
}

.status-row {
    display: flex;
    flex-direction: row;
}

.status {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #EFEFEF80;
  margin-right: 5px;

  &--red {
    $c: #B84A32;
    background-color: $c;
    border-color: lighten($c, 10%);
    box-shadow: 0 0 7px rgba($c, 0.3);
  }
  &--orange {
    $c: #e67e22;
    background-color: $c;
    border-color: lighten($c, 10%);
    box-shadow: 0 0 7px rgba($c, 0.3);
  }
  &--yellow {
    $c: #DFB727;
    background-color: $c;
    border-color: lighten($c, 10%);
    box-shadow: 0 0 7px rgba($c, 0.3);
  }
  &--green {
    $c: #0be881;
    background-color: $c;
    border-color: lighten($c, 10%);
    box-shadow: 0 0 7px rgba($c, 0.3);
  }
  &--blue {
    $c: #0fbcf9;
    background-color: $c;
    border-color: lighten($c, 10%);
    box-shadow: 0 0 7px rgba($c, 0.3);
  }
  &--purple {
    $c: #3c40c6;
    background-color: $c;
    border-color: lighten($c, 10%);
    box-shadow: 0 0 7px rgba($c, 0.3);
  }
  &--pink {
    $c: #ef5777;
    background-color: $c;
    border-color: lighten($c, 10%);
    box-shadow: 0 0 7px rgba($c, 0.3);
  }
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  &__box {
    width: 75%;
    height: 65%;
    background-color: green;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  &__box-half {
  }
}

.pdf {
    // overflow-x: auto;
    // max-height: calc(100vh - 200px);
    // .react-pdf__Page__textContent {
    //     background-color: transparent !important;
    // }
    .react-pdf__Page {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
        canvas {
            border-radius: 4px;
        }
    }
}

.divider {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 10px 0;
}

.badge {
    padding: 2px 6px;
    border-radius: 2px;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.3);
}

.gauge {
    position: relative;
    width: 100%;
    height: 350px;
    // z-index: inherit;

    .recharts-responsive-container {
        z-index: 0;
        position: relative;
        margin-bottom: -50px;
    }

    input[type='number'] {
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.15);
        border-style: solid;
        border-width: 1px;
        border-color: rgba(255, 255, 255, 0.2);
        width: 100%;
        font-size: 14px;
        color: white;
        border-radius: 4px;
        &::placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
        &:focus {
            outline: none;
        }
    }

    h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        font-size: 42px;
    }
    p {
        position: absolute;
        top: calc(50% + 80px);
        left: 50%;
        transform: translate(-50%, -50%);
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        font-size: 18px;
    }
    &__pie {
        position: absolute;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.3))
    }
}

.fill-bar {
    $h: 40px;

    width: 100%;
    height: $h;
    background-color: #EFEFEF40;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    &__details {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        width: 100%;
        height: $h;
        position: absolute;
        top: 0;
        left: 0;
        
        p {
          font-family: "Korolev Medium", sans-serif;
        }
    }

    &__fill {
        width: 50%;
        height: $h;
        background-color: rgba(255, 255, 255, 0.2);
        transition: all 0.8s;
        box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.15);
    }

    &__fill--gold {
        background-color: rgba(#ff9f43, 0.3);
    }
    &__fill--red {
      background-color: rgba(#f53b57, 0.3);
    }
  
    &__fill--yellow {
      background-color: rgba(#ffc048, 0.3);
    }

    &__fill--green {
      background-color: rgba(#0be881, 0.3);
    }
    &__fill--green--light {
      background-color: #62ab3757;
    }
    &__fill--blue {
      background-color: rgba(#00a5cf, 0.3);
    }

}

.loader {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    position: relative;
    &__bg {
        width: 50px;
        height: 50px;
        // background-color: orange;
        border-radius: 25px;
        border-style: solid;
        border-width: 4px;
        border-color: rgba(255, 255, 255, 0.1);
    }
    &__fill {
        width: 50px;
        height: 50px;
        // background-color: orange;
        border-radius: 25px;
        border-style: solid;
        border-width: 4px;
        border-color: rgba(255, 255, 255, 0.2);
        
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: 50%;
        transform: rotate(40deg);
        clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
        animation-name: animLoadingSpinner;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes animLoadingSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}