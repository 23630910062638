@import 'src/styles/variables';

.background {
  background-attachment: fixed;
  background-image: url('../assets/bg_desertGreen_03.png');
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  padding: 15px;
  text-align: left;
}

.rd-logo {
  height: auto;
  padding-bottom: 20px;
  width: 200px;
}

.copyright {
  color: $lightGray50;
  font-family: $korolevMed;
  font-size: 12px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.policy-container {
  background-color: #262a25;
  border-radius: 28px;
  padding: 32px;
  width: 80%;
}

.policy-header {
  color: $white;
  font-family: $magistralCondMed;
  font-size: 40px;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
}

.policy-body {
  color: $white;
  font-family: $korolevMed;
  font-size: 16px;
  line-height: 19px;
}

.policy-section-header {
  color: #a5af96;
  font-family: $korolevMed;
  font-size: 20px;
  margin-bottom: 8px;
  text-transform: uppercase;
}
