@import "./styles/variables";
@import "./styles/buttons.scss";
@import "./styles/forms.scss";

html {
  background-color: $black;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  text-align: center;
  padding-right: 0 !important;
}

::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
  border-radius: 0 !important;
}

::-webkit-scrollbar-track {
  background-color: #484848 !important;
  border-radius: 0 !important;
}

::-webkit-scrollbar-thumb {
  background: #171717;
  border-radius: 0 !important;
}
::-webkit-scrollbar-thumb:hover {
  background: #1c1c1c !important;
  border: 0.1px solid #565656 !important;
}

.fixed {
  overflow-y: hidden !important;
}

select option {
  color: $white;
  background-color: #565656;
}

@import "./css/rd.scss";
@import "./css/fa.css";
@import "./css/quill-editor.scss";
