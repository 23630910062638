@import "styles/variables";

// Applicant profile Animations
@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slide-in-from-bottom {
  from {
    transform: translatey(100%);
  }
  to {
    transform: translatey(0%);
  }
}

@keyframes slide-up {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pop-in {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

//Staffing button
.back-button {
  width: 100%;

  a {
    text-decoration: none;
  }
}

// Applicant (Tab-bar/tab.jsx)
.tab-bar-container {
  display: flex;

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dae0bc;
    height: 45px;
    color: #3e453d;
    font-family: "Korolev Medium", sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: capitalize;
    max-width: 450px;
    width: 25%;
    transition: 0.3s ease-in-out;
    transform: translateX(-100%);
    animation: slide-in 0.3s ease-in-out forwards;

    &:hover {
      background-color: #a5af96;
    }
  }

  .active {
    background-color: #a5af96;
    transform: translateX(0%);
  }

  .left {
    border-top-left-radius: 28px;
  }

  .right {
    border-top-right-radius: 28px;
  }
}

// Applicant-Preview
.applicant-profile-box {
  animation: slide-in-from-bottom 0.3s ease-in-out !important;
  border-bottom-right-radius: 18px !important;
  border-bottom-left-radius: 18px !important;
  overflow: auto;
  background-color: transparent !important;
}

.portal-preview {
  background-color: transparent;
  color: $white;
  height: calc(100vh - 80px);
  overflow: auto;
  width: 100%;
}

.user-info-container {
  margin-bottom: 30px;
  padding-left: 31px;
  padding-top: 57px;
}

.back-button-padding {
  padding-top: 26px;
}

.user-info-header {
  align-items: flex-end;
  display: flex;
  margin-bottom: 30px;
}

.user-icon {
  margin-right: 20px;
  width: 87px;
}

.user-info-name {
  color: $white;
  font-family: $magistralCondMed;
  font-size: 24px;
  line-height: 32.5px;
  text-transform: capitalize;
}

.user-info-small-header {
  color: $white;
  font-family: $korolevMed;
  font-size: 12px;
  line-height: 12px;
  margin-top: 10px;
  text-transform: capitalize;
}

.row {
  align-items: center;
  display: flex;
}

.icon {
  height: auto;
  margin-right: 15px;
  width: 20px;
}

.fa-icon {
  font-size: 20px !important;
  height: auto;
  margin-left: 0;
  margin-right: 15px;
  width: 20px;
  &:hover {
    color: #FFFFFF;
  }
}

.edit-icon {
  cursor: pointer;
  font-size: 15px !important;
  margin-left: 15px;
  &:hover {
    color: #00A5CF;
  }
}

.section-container {
  border-top: 1px solid $lightGray50;
  margin-bottom: 30px;
  padding-left: 31px;
  padding-top: 16px;
}

.section-header {
  color: $white;
  font-family: $magistralCondMed;
  font-size: 24px;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.section-data {
  color: $white;
  font-family: $korolevMed;
  font-size: 15px;
  line-height: 32px;
}

.tabs-container {
  margin-bottom: 45px;
}

.psq-button-container {
  margin: auto;
  max-width: 300px;
}

.back-button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: $white;
  display: flex;
  font-family: $korolevMed;
  padding-left: 31px;
  margin-top: 16px;
  text-align: left;
}

.blue-hover {
  &:hover {
    color: $highlightBlue;
  }
}
.navy-hover {
  &:hover {
    color: $projectsAqua;
  }
}

.green-hover {
  &:hover {
    color: $highlightGreen;
  }
}

//Applicant Tags
.tag-main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 25px;

    button {
      font-family: "Korolev Medium", sans-serif;
      width: 180px;
      height: 55px;
      font-size: 24px;
      border-radius: 8px;
      border: 3px solid #929e7f;
      transition: 0.3s ease-in-out;

      &:hover {
        border: 3px solid #dae0bc;
        background-color: #929e7f;
      }
    }
  }

  .box-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;

    .icons {
      &--direction {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;

        i {
          width: 100%;
          height: 100%;
          font-size: 50px !important;

          &:hover {
            color: #a5af96;
          }
        }

        .remove {
          &:hover {
            color: #cc0000;
          }

          &--top {
            margin-bottom: 30px;
          }
        }

        .add {
          &:hover {
            color: #00b900;
          }
        }
      }
    }

    .icons--direction {
      min-width: 100px;
      & .arrow-btn {
        background-color: transparent;
        border-radius: 8px;
        border:1px solid #EFEFEF80;
        width: 70px;
        height: 70px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;

        &--disabled {
          cursor: not-allowed;
        }

        &.add-btn:hover {
          background-color: #144D5D;
        }
        &.remove-btn:hover {
          background-color: #144D5D;
        }
        & img {
          transition: 0.3s;
          width: 45px;
          opacity: 0.7;
        }

        &:hover img {
          opacity: 1;
        }
      }
    }

    .box {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      height: 100%;
      width: 100%;
      gap: 20px;

      h2 {
        font-family: "Korolev Medium", sans-serif !important;
        font-weight: 300;
      }
    }

    .tags {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      border: 1px solid #EFEFEF80;
      padding: 10px;
      gap: 10px;
      overflow-y: auto;
      animation: pop-in 0.5s ease-in-out forwards;
      animation-delay: calc(0.1s * var(--i));
      overflow-x: hidden !important;

      .disabled:before {
        opacity: 1 !important;
        height: 0 !important;
      }
    }

    .active {
      border-radius: 8px;
      padding: 0;
    }
    .active-box {
      padding: 0 20px 0 10px;
      .header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        h2 {
          width: 100%;
        }
        i {
          color: #00b900;

          &:hover {
            color: #a5af96;
          }
        }
      }
    }

    .available-box {
      padding-right: 10px;

      .header {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .icons {
          i {
            cursor: pointer;
            color: #dae0bc;

            &:hover {
              color: #a5af96;
            }
          }

          .edit {
            color: #64b5f6;
          }

          .add {
            color: #64b5f6;
          }

          .editing {
            color: #ffb74d;
          }
        }
      }
    }
  }
}
.MuiDialogTitle-root {
  padding-bottom: 0 !important;
}
.MuiDialog-paper {
  width: 100%;
  padding: 0 !important;

  .tag-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    max-width: 500px;
    .tag-modal {
      width: 100%;
      height: 100%;

      &-box {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      &-errors {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
      }

      &-error {
        font-size: 14px;
        color: $cancelRed;
      }

      .modal-content {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;

        .row {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 30px;

          &-half{
            width: 50%;

          }

          select {
            font-family: "Korolev Medium", sans-serif;
            width: 95%;
            height: 45px;
            font-size: 15px;
            border-radius: 10px;
            border: 1px solid #a5af96;
            transition: 0.3s ease-in-out;
            padding: 10px;
            color: black;
            background-color: white;
          }
        }

        .first-row {
        }

        .last-row {
          margin: 0;
        }

        .input {
          display: flex;
          flex-direction: column;
          width: 100%;

          &-checkbox{
            margin-top: 33px;
            flex-direction: row-reverse;
            display: flex;
            align-items: center;
            margin-right: 5%;

            & label {
              margin-left: 10px;
              margin-top: 5px;
            }

            & input {
              width: 10px;
              height: 10px;
            }
          }
          
          input {
            font-family: "Korolev Medium", sans-serif;
            width: 95%;
            height: 45px;
            font-size: 15px;
            border-radius: 8px;
            border: 1px solid #a5af96;
            transition: all 0.3s ease-in-out;
            padding: 10px;
            background-color: white;

            &:focus {
              border: 1px solid $highlightBlue;
            }
          }
        }
      }
      
      & label {
        font-size: 16px;
        color: #2A3D1F;
        font-family: '"MagistralCondW08-Medium", sans-serif';
        font-style: normal;
        font-weight: 500;
        margin: 10px 0 5px;
        text-align: left;
        text-transform: uppercase;
      }
    }

    //button {
    //  border:none;
    //  //background-color: $highlightBlue;
    //  border-radius: 10px;
    //  color: #EFEFEF;
    //  font-family: "MagistralCondW08-Medium";
    //  font-size: 0.875rem;
    //  font-weight: 500;
    //  padding: 13px 20px;
    //  text-transform: capitalize;
    //  transition: all 0.3s;
    //}
  }
}

.color {
  width: 80px !important;
  border: 1px solid #a5af96;
  background-color: white;
}

.existing {
  width: 20px !important;

  &:hover {
    opacity: 0.9;
    color: white;
  }
}

// Tag SpreadSheet
.spreadsheet {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border-spacing: 0;

  .first-td {
    border-top-left-radius: 6px;
    padding-left: 25px !important;
    width: 100%;
  }

  .last-td {
    border-top-right-radius: 6px;
    width: 100%;
  }

  .app-arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }

  thead {
    .ss-headers {
      display: grid;
      grid-template-columns: 25% 25% 22% 28%;
      border-bottom: 1px solid #EFEFEF80;
      background: linear-gradient(to right, rgba(0, 165, 207, 0.3411764706), rgba(239, 239, 239, 0));

      td {
        display: flex;
        align-items: center;
        background-color: transparent;
        padding: 20px;
        color: #efefef;
        font-family: "MagistralCondW08-Medium", sans-serif;
        font-size: 20px;
        width: 100%;

        .arrow {
          font-size: 15px !important;
        }

        .order {
          color: black;
        }

        i:hover {
          cursor: pointer;
          opacity: 1;
          color: #64b5f6;
        }

      }
    }
  }

  tbody {
    background-color: transparent;
    .ss-content {
      display: grid;
      grid-template-columns: 4% 24% 24% 22% 26%;
      text-align: left;
      background-color: transparent;
      border-bottom: 1px solid #EFEFEF80;
      width: 100%;
      transition: 0.3s;
      align-items: center;

      td {
        align-items: center;
        padding: 10px;
        width: 100%;
        animation: pop-in 0.3s ease-in-out;
        transition: 0.3s;
        display: table;
        table-layout: fixed;
        word-wrap: break-word;

        &:first-child {
          min-height: 60px;
        }
      }

      &:hover:not(.active-tag){
        animation: pop-out 0.3s ease-in-out;
        animation-fill-mode: forwards;
        background-color: #144d5d74;
        cursor: pointer;
      }

      .description {
        width: 100%;
      }
    }

    .active-tag {
      background-color: #144D5D;
      cursor: pointer;
    }
  }
}
