@import "styles/variables";

.contractsTags {
  &__container {
    border: 1px solid rgba(239, 239, 239, 0.5019607843);
    border-radius: 10px;
    height: calc(100vh - 206px);
    overflow: auto;
    padding: 29px 22px;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: row;
  }

  &__tags {
    display: flex;
    flex-direction: column;
    width: calc(100% - 10px);

    &-list {
      border: 1px solid rgba(239, 239, 239, 0.5019607843);
      border-radius: 10px;
      overflow: hidden;
    }

    &-listItem {
      display: grid;
      grid-template-columns: 3% 17% 20% 21% 28% 5% 6%;
      text-align: left;
      background-color: transparent;
      border-bottom: 1px solid #EFEFEF80;
      width: 100%;
      transition: 0.3s;
      align-items: center;
      border-bottom: 1px solid rgba(239, 239, 239, 0.5019607843);

      &-header {
        display: grid;
        align-items: center;
        border-radius: 10px 10px 0 0;
        grid-template-columns: 3% 17% 20% 21% 28% 5% 6%;
        border-bottom: 1px solid rgba(239, 239, 239, 0.5019607843);

        td {
          align-items: center;
          padding: 10px;
          width: 100%;
          animation: pop-in 0.3s ease-in-out;
          transition: 0.3s;
          display: table;
          table-layout: fixed;
          word-wrap: break-word;

          &:first-child {
            min-height: 60px;
          }
        }
      }

      &--active {
        background: linear-gradient(to right, #62AB3757, #efefef00);
        cursor: pointer;

        &--blue {
          background: linear-gradient(to right, #12343D, #efefef00);
        }
      }

      &-text {
        color: #efefef;
        font-family: "MagistralCondW08-Medium", sans-serif;
        font-size: 20px;

        & i {
          font-size: 18px !important;
          cursor: pointer;
          margin-left: 10px;
        }
      }

      td {
        align-items: center;
        padding: 10px;
        width: 100%;
        animation: pop-in 0.3s ease-in-out;
        transition: 0.3s;
        display: table;
        table-layout: fixed;
        word-wrap: break-word;

        &:first-child {
          min-height: 60px;
        }
      }

      &:hover {
        animation: pop-out 0.3s ease-in-out;
        animation-fill-mode: forwards;
        background-color: #efefef40;
        cursor: pointer;
      }
    }

    &__modal {
      &-error {
        position: absolute;
        font-size: 14px;
        color: #a20601;
        top: 15px;
        right: 0;

        &--add {
          position: absolute;
          font-size: 14px;
          color: #a20601;
          top: 15px;
          left: 0;
        }
      }

      &-buttonContainer {
        margin-top: 30px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
      }

      &-loading {
        position: absolute;
        top: -40px;
        right: 100px;
      }

      &-button {
        background-color: $highlightGreen;
        border: 1px solid $highlightGreen;
        border-radius: 10px;
        color: $white;
        font-family: $magistralCondMed;
        font-size: 1rem;
        font-weight: 500;
        padding: 13px 20px;
        text-transform: capitalize;
        transition: all 0.3s;

        &:hover {
          border: 1px solid $highlightGreen;
          color: $highlightGreen;
          background-color: transparent;
        }

        &-blue {
          background-color: $highlightBlue;
          border: 1px solid $highlightBlue;

          &:hover {
            border: 1px solid $highlightBlue;
            color: $highlightBlue;
            background-color: transparent;
          }
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 0;
    right: 0;

    &-group {
      right: 270px;
    }

    &--input {
      background-color: $lightGray25;
      border: 1px solid $white;
      border-radius: 6px;
      color: $white;
      font-family: $korolevMed;
      font-size: 15px;
      padding: 8px 12px;
      width: 250px;
    }
  }
}
