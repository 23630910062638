@import "styles/variables";

.applicantCards {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__container {
    height: calc(100vh - 206px);
    overflow: auto;
    padding: 0 22px 0 0;
    width: 100%;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  @media only screen and (min-width: 1040px)  {
    &__block {
      width: 50%;
    }
  }
}

.applicantCard {
  border-radius: 5px;
  border: 0.1px solid $lightGray50;
  overflow: hidden;
  transition: all 0.5s;

  &--hidden {
    opacity: 0;
  }

  &__header {
    display: flex;
    gap: 20px;

    &-container {
      display: flex;
      background: linear-gradient(to right, rgba(0, 165, 207, 0.3411764706), rgba(239, 239, 239, 0));
      padding: 15px;
      justify-content: space-between;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;


    &--horizontal {
      display: flex;
      flex-direction: column;
      list-style: none;
    }

    @media only screen and (min-width: 1210px)  {
      &--horizontal {
        display: flex;
        flex-direction: row;
        list-style: none;
      }
    }
  }

  &__item {
    &:after {
      display: inline-block;
      width: 100%;
      height: 0.5px;
      margin-top: 5px;
      background-color: $lightGray50;
      content: "";
    }

    &:first-child {
      margin-top: 10px;
    }

    &:last-child {
      margin-bottom: 10px;

      &:after {
        content: none;
      }
    }

    &--horizontal {
      position: relative;
      width: 100%;

      &:after {
        position: absolute;
        top: 0;
        display: inline-block;
        width: 0.5px;
        height: 100%;
        background-color: $lightGray50;
        content: "";
      }

      &:first-child {
        &:after {
          content: none;
        }
      }
    }

    &-content {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      gap: 15px;
    }

    @media only screen and (max-width: 1100px)  {
      &-content {
        display: flex;
        flex-direction: column;
        align-items: inherit;
      }
    }

    &-name {
      width: 45%;
      color: $white;
      font-weight: 500;
      font-size: 16px;
      text-align: left;
    }


    @media only screen and (max-width: 1100px)  {
      &-name {
        width: 100%;
      }
    }

    &-value {
      width: 55%;
      color: $white;
      font-weight: 500;
      font-size: 16px;
      text-align: left;
    }


    @media only screen and (max-width: 1100px)  {
      &-value {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 1040px)  {
  .applicantCards {
    display: flex;
    flex-direction: row;
  }
}