@import "styles/variables";

.nav {
  width: 100%;
  height: 80px;
  background-color: $black;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: space-between;
  align-items: center;

  &__logo {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 60%;
    padding: 0px 20px;
    position: relative;
    text-transform: uppercase;
    top: 3px;
    img {
      height: 100%;
    }
  }

  &__title-green {
    color: $highlightGreen;
    font-family: $magistralCondMed;
    font-size: 28px;
    margin-left: 42px;
  }

  &__title-blue {
    color: $highlightBlue;
    font-family: $magistralCondMed;
    font-size: 28px;
    margin-left: 42px;
  }

  &__title-purple {
    color: $highlightPurple;
    font-family: $magistralCondMed;
    font-size: 28px;
    margin-left: 42px;
  }

  &__title-navy {
    color: $projectsAqua;
    font-family: $magistralCondMed;
    font-size: 28px;
    margin-left: 42px;
  }

  &__actions {
    padding: 0px 20px;
    height: 100%;
  }

  &__dropdown {
    height: 100%;
    .nav__dropdown-items {
      top: 0px;
      opacity: 1;
    }
    .nav__dropdown-card-closed {
      border-color: rgba(255, 255, 255, 0);
      color: white;
    }
    .nav__dropdown-card-open {
      border-color: rgba(255, 255, 255, 0.2);
      color: white;
    }
    .icon-button {
      background-color: transparent;
    }
  }

  &__dropdown-card-closed,
  &__dropdown-card-open {
    display: flex;
    flex-direction: row;
    width: 240px;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-style: solid;
    border-width: 0px 2px;
    color: transparent;
    padding: 0px 20px;
    transition: all 0.2s;
  }

  &__dropdown-title {
  }

  &__dropdown-items {
    background-color: rgba(20, 20, 20, 0.9);
    border-style: solid;
    border-width: 0px 2px 2px 2px;
    border-color: rgba(255, 255, 255, 0.2);
    border-radius: 0px 0px 15px 15px;
    padding: 0px 0px 5px 0px;
    z-index: 10;
    position: relative;
    top: -50px;
    transition: all 0.2s;
    opacity: 0;
    overflow: hidden;
  }

  &__dropdown-item {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 45px;
    line-height: 45px;
    padding: 0px 20px;
    border-style: solid;
    border-width: 2px 0px 0px 0px;
    border-color: rgba(255, 255, 255, 0.2);
    &:hover {
      cursor: pointer;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    &::before {
      content: "\f105";
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.3);
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      margin-right: 20px;
    }
  }
}

.icon-button {
  width: 35px;
  height: 35px;
  border-radius: 17px;
  user-select: none;
  transition: all 0.15s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  i {
    transition: all 0.2s;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
  &--arrow-active {
    i {
      transform: rotate(180deg);
    }
  }
}
