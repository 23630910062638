@import "styles/variables";

.emailTemplates {
  display: flex;
  flex-direction: row;
  padding: 0;
  border: 1px solid #efefef80;
  border-radius: 10px;
  height: calc(100vh - 206px);
  overflow: auto;
  width: 100%;

  &__templates {
    height: 100%;
    border-right: 1px solid #efefef80;
    padding: 33px 22px 22px;
    width: 35%;
    min-width: 325px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-category {
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.00938em;
      color: $white;
      font-family: $korolevMed;
      font-size: 1rem;
      text-transform: uppercase;
    }

    &-stage {
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.00938em;
      color: $white;
      font-family: $korolevMed;
      font-size: 1rem;
      text-transform: capitalize;
      cursor: pointer;
    }
  }

  &__categories {
    background-color: #f0f0f00a;
    border: 1px solid #f0f0f080;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 10px;
    }
  }

  &__stages {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 6px;
    min-height: 40px;
    padding: 0 5px;
    transition: all .3s;

    &--hidden {
      opacity: 0;
    }
  }

  &__stage {
    display: flex;
    gap: 10px;
    padding: 2px 5px;
    transition: all 0.3s;

    &-active {
      background: linear-gradient(to right, #62AB3757, #efefef00);
    }

    &:hover {
      background-color: $lightGray25;
    }

    &-images {
      display: flex;
      gap: 5px;
    }
  }

  &__edit {
    height: 100%;
    overflow-y: auto;
    position: relative;
    width: 65%;

    &-container {
      padding: 0 45px 22px;
    }

    &-error {
      color: #a20601;
      font-size: 14px;
      position: absolute;
      top: 46px;
      left: -250px;
      bottom: 10px;
      font-family: $korolevMed;
    }

    &-header {
      position: relative;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f0f0f080;
      padding-bottom: 13px;
    }

    &-button {
      position: fixed;
      right: 100px;
      top: 190px;
      display: flex;
      justify-content: flex-end;
      z-index: 7;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: #26351d;
      position: sticky;
      top: 0;
      z-index: 5;
      padding: 30px 45px 0;

      &--transparent {
        background-color: transparent;
      }
    }

    &-infoName {
      color: $white;
      font-family: $korolevMed;
      font-size: 25px;
      line-height: 34px;

      &--small {
        font-size: 16px;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-top {
      display: flex;
      margin-top: 20px;

      &--left {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 40%;
        margin: auto;
      }
      &--right {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 60%;
      }
    }

    &-checkBox {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    &-field {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &--name {
        font-weight: 400;
        line-height: 1.5;
        color: $white;
        font-family: $korolevMed;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }
}