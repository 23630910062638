@import "styles/variables";

.tab-container {
  border-bottom: 1px solid $lightGray50;
}

.first-tab {
  border-top: 1px solid $lightGray50;
}

.tab-button {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-left: 11px solid transparent;
  color: $white;
  display: flex;
  font-family: $korolevMed;
  font-size: 15px;
  padding: 18px 36px 18px 20px;
  text-align: left;
  text-transform: capitalize;
  width: 100%;

  &:hover {
    background-color: $lightGray25;
  }
}

.blue-active {
  background: linear-gradient(to right, #00A5CF57, #efefef00);
  border-left: 11px solid $highlightBlue;
}

.green-active {
  background: linear-gradient(to right, #62AB3757, #efefef00);
  border-left: 11px solid $highlightGreen;
}

.purple-active {
  background: linear-gradient(to right, #531CB357, #efefef00);
  border-left: 11px solid $highlightPurple;
}

.navy-active {
  background: linear-gradient(to right, rgba(20, 26, 102, 0.92), #efefef00);
  border-left: 11px solid $projectsAqua;
}

.tab-arrow-icon {
  margin-left: auto;
}

.blue-arrow {
  &:hover {
    color: $highlightBlue;
  }
}

.navy-arrow {
  &:hover {
    color: $projectsAqua;
  }
}

.green-arrow {
  &:hover {
    color: $highlightGreen;
  }
}

.purple-arrow {
  &:hover {
    color: $highlightPurple;
  }
}
