@import "styles/variables";

// Contracts Table

.contracts {
  &__manage {
    &-row {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 130px 75px 33px 54px;
      gap: 15px;
      padding: 20px 10px 20px 20px;
      width: calc(100% - 75px);
      min-height: 77px;
      align-items: center;

      & p {
        font-family: $korolevMed;
        line-height: 22px;

        &:nth-child(2) {
          line-height: 37px;
        }
      }

      & button {
        justify-content: center;
      }

      &-error {
        position: absolute;
        bottom: 0;
        left: 20px;
        font-size: 13px;
        font-family: $korolevMed;
        color: $cancelRed;
      }
    }

    &-header {
      display: grid;
      grid-template-columns: 1fr 130px 75px 33px 54px 50px;
      gap: 15px;
      padding: 20px;

      & p {
        color: $white;
        font-family: $magistralCondMed;
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        text-align: left;
        text-transform: capitalize;
        vertical-align: middle;
      }
    }

    &-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > :nth-child(2) {
        margin: 0 auto;
        width: 40px;
        padding-right: 20px;
      }
    }
  }
}

.contracts-loading-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.contracts-table {
  border-collapse: collapse;
  width: 100%;

  //&-templates {
  //  & > :nth-child(1) {
  //    & tbody {
  //      visibility: hidden;
  //      height: 1px;
  //
  //      & tr {
  //        visibility: hidden;
  //      }
  //
  //      & td {
  //        visibility: hidden;
  //      }
  //    }
  //  }
  //}

  &-container {
    tbody {
      display: block;
      height: calc(100vh - 280px);
      overflow: auto;
    }
    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;/* even columns width , fix width of table too*/
    }
    thead {
      width: calc(100% - 10px)/* scrollbar is average 1em/16px width, remove it from thead width */
    }

    & .arrow {
      width: 40px;
      padding-left: 10px;
    }

    & .contracts-header {
      padding: 5px 20px;
      height: 70px;
    }

    & .contract-data {
      padding: 20px;
    }

    & tr {
      & :nth-child(6), :nth-child(5), :nth-child(4) {
        width: 96px;

        @media (min-width: 1600px) {
          width: 140px;
        }
      }
      & :nth-child(2) {
        width: 148px;

        @media (min-width: 1400px) {
          width: 20%;
        }

        @media (min-width: 1600px) {
          width: 16%;
        }
      }
      & :nth-child(3) {
        width: 94px;

        @media (min-width: 1600px) {
          width: 120px;
        }
      }
      & :nth-child(7) {
        width: 120px;

        @media (min-width: 1600px) {
          width: 148px;
        }
      }
    }
  }

  & tr {
    //display: table;
  }

  &-body {
    display: block;
    height: calc(100vh - 312px);
    overflow: auto;

    & tr {
      display: table;
    }
  }
}

.contracts-header {
  color: $white;
  font-family: $magistralCondMed;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  padding: 25px 25px 15px;
  text-align: left;
  text-transform: capitalize;
  vertical-align: middle;
}

.contracts-button {
  color: $highlightBlue;
  background-color: transparent;
  border: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: $korolevMed;
  font-size: 18px;
  font-weight: 700;
  margin-top: -15px;

  & i.fa-solid.blue-arrow {
    color: $highlightBlue;
  }
}

.contract-row {
  &:hover {
    background-color: $lightGray25;
  }

  &--selected {
    & > td {
      position: relative;
      z-index: 2;


      &:first-child:after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: calc(80vw - 100px);
        max-width: calc(100vw - 400px);
        height: 100%;
        content: "";
        background-image: linear-gradient(to right, #62AB3757, #efefef00);
      }
    }
    //background: linear-gradient(to right, #62AB3757, #efefef00);
  }
}

.row-border {
  border-bottom: 1px solid $lightGray50;
}

.contract-data {
  color: $white;
  font-family: $korolevMed;
  padding: 25px;

  &--centered {
    text-align: center;
  }
}

.title {
  width: 40%;
}

.url {
  width: 25%;
}

.personnel {
  width: 20%;
}

.deadline {
  width: 13%;
}

.arrow {
  width: 2%;
}

// Create New Contract

.contract-form-section {
  margin-bottom: 35px;
}

.contract-form-section-header {
  position: relative;
  border-bottom: 1px solid $lightGray50;
  color: $white;
  font-family: $magistralCondMed;
  font-size: 25px;
  line-height: 34px;
  padding-bottom: 8px;

  &-dark {
    color:$black;
    text-align: left;
    border-bottom: 1px solid $black ;
  } 
}

.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contract-form-section-content {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  margin-top: 30px;
  width: 100%;
}

.contract-form-set {
  position: relative;
  margin-bottom: 30px;
  width: 33%;
}

.contract-form-quill {
  & .ql-snow.ql-toolbar {
    background-color: $white;
    border-radius: 5px 5px 0 0;
  }

  & .ql-container.ql-snow {
    border-radius: 0 0 5px 5px;
  }

  & .ql-editor {
    min-height: 200px;
    max-height: calc(100vh - 700px);
  }
}

.contract-form-set-half {
  position: relative;
  margin-bottom: 30px;
  width: 50%;
}

.contract-form-set-full {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
}

.contract-form-label {
  color: $white;
  font-family: $korolevMed;
  font-size: 15px;
  margin-bottom: 8px;
}

.contract-form-input {
  background-color: $lightGray25;
  border: 1px solid $white;
  border-radius: 6px;
  color: $white;
  font-family: $korolevMed;
  font-size: 15px;
  padding: 8px 12px;
  width: 95%;

  &-search {
    position: relative;

    &::after {
      content: "";
      background-image: url('../assets/icon-bar-search.png');
    }
  }

  &-capitalized {
    text-transform: capitalize;
  }
}

.contract-form-input-email {
  background-color: $lightGray25;
  border: 1px solid $white;
  border-radius: 6px;
  color: $white;
  font-family: $korolevMed;
  font-size: 15px;
  padding: 8px 12px;
  width: 98.5%;
}

.contract-form-input::placeholder,
.contract-form-input-email::placeholder {
  color: $lightGray50;
}

.contract-form-button-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  width: 100%;
}

.create-button {
  background-color: $highlightGreen;
  border: 1px solid $highlightGreen;
  border-radius: 10px;
  color: $white;
  font-family: $magistralCondMed;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 13px 20px;
  text-transform: capitalize;
  transition: all 0.3s;
  
  &-blue {
    background-color: $highlightBlue;
    border: 1px solid $highlightBlue;
    padding: 8px 20px;
    font-size: 1rem;
  }
  
  &:hover {
    background-color: transparent;
    border: 1px solid $lightGray50;
  }

  &-container {
    position: absolute;
    right: 40px;
    top: 115px;
  }

  &-error {
    position: absolute;
    bottom: -5px;
    right: 117px;
    font-size: 13px;
    font-family: $korolevMed;
    color: $cancelRed;
    width: 200px;
  }
}

.add-button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: $white;
  display: flex;
  font-family: $korolevMed;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
  &:hover {
    background-color: transparent;
    color: $highlightGreen;
  }
}

.edit-icon {
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    color: #62ab37;
  }
}

.copy-icon {
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    color: $highlightGreen;
  }
  &:active {
    color: #c9ffb0;
  }
}

.division-subdivision {
  color: $white;
  font-family: $korolevMed;
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 16px;
  text-transform: capitalize;
  width: 100%;
  &:hover {
    color: $highlightGreen;
    cursor: pointer;
  }

  .divisions-email-btn{
    display: inline-block;

    svg {
      color: #c2caaa;
    }
  }
}

.selected {
  color: $highlightGreen;
}

.header-content {
  display: flex;
  justify-content: space-between;

  .contract-filter {
    display: flex;
    align-items: center;
    width: 25%;
  }
}

.contract-rich-editor {
  width: 100%;

  .quill {
    border: 1px solid #a5af96;

    & .ql-container{
      border: none;
    }
    & .ql-toolbar{
      border-bottom: 1px solid #a5af96;
    }
    & .ql-editor {
      min-height: 300px;
    }
  }
}
.contract-quill {
  color: $black;
  & .ql-editor {
    font-size: 16px;
    max-height: calc(100vh - 670px);
    min-height: 200px;
  }
  & h1 {
    color: inherit;
  }
  & .ql-container {
    border-radius: 0 0 10px 10px;
    padding: 5px;
  }
}

.contract-switches {
  color: $primaryGreen;
  font-size: 16px;
  text-transform: uppercase;
  text-align: start;
  font-family: $magistralCondMed;
  margin: 10px 0 -15px;
}
