@use "./variables";

select {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: variables.$borderRadius;
  color: white;
  outline: solid 0px rgba(255, 255, 255, 0.15);
  padding: 10px;
  transition: all 0.2s;
  width: 100%;
  &:focus {
    outline: solid 4px rgba(255, 255, 255, 0.15);
    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
    transition: all 0.2s;
  }
}

// Accounting Module

%accounting-text-input {
  background-color: variables.$lightGray25;
  border: 1px solid variables.$white;
  border-radius: variables.$borderRadius;
  color: variables.$white;
  margin: 8px 0 36px;
  padding: 12px 15px;
  transition: 0.2s ease-in-out;
  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
    transition: all 0.2s;
  }
}
