@import "../styles/variables";

.app-bar-nav-buttons {
  font-family: "MagistralCondW08-Medium";
  font-size: 20px;
  color: "#D9DADB";
}

.logo {
  height: auto;
  margin: 10px 0 0 10px;
  width: 42px;
}

.nav-menu-btn {
  border-radius: 0px !important;
}

.nav-menu-btn-clicked {
  width: 152px;
  border-left: 1px solid $white !important;
  border-right: 1px solid $white !important;
}

.menu-title {
  color: $white;
  font-family: $korolevMed;
  font-size: 15px;
  margin-right: 53px;
}

.menu-item {
  color: $white;
  background-color: $black !important;
  border-top: 1px solid $white !important;
  width: 150px;

  .icon {
    color: $black;
    transition: 0.2s ease-in-out !important;
  }

  p {
    width: 100%;
    color: $white;
    font-family: $korolevMed;
    font-size: 15px;
    font-weight: 100;
    padding-right: 0px;
  }

  .menu-option {
    text-align: left;
  }

  &:hover {
    .icon {
      color: $white;
    }
  }
}

.MuiList-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-left: 1px solid $white;
  border-right: 1px solid $white;
}

@media screen and (max-width: 1280px) {
  .menu-item {
    .icon {
      color: $white !important;
    }
  }
}

@media screen and (max-width: 900px) {
  .nav-menu-btn {
    margin-left: 10px !important;
  }
}

@media screen and (max-width: 500px) {
  .nav-menu-btn-clicked {
    width: 152px;
    border-left: 1px solid $white !important;
    border-right: 1px solid $white !important;
    margin-left: 35px !important;
  }
}
