@use "../styles/variables";

// Email Templates

.file-row {
  align-items: center;
  display: flex;
  margin-bottom: 24px;
}

.file-selection {
  display: flex;
  align-items: center;
}

.half-width {
  width: 50%;
}

.third-width {
  width: 33%; 
}

.active-label {
  color: variables.$white;
  font-family: variables.$korolevMed;
  font-size: 15px;
}

.pre-screening-alert {
  font-family: variables.$korolevMed;
  margin-bottom: 16px;
  width: 100%;
}

// Existing styles

.padded {
    padding: 0px !important;
}

.main-container {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
    // height: auto;
    height: calc(100vh - 240px);
    overflow-y: scroll;

    // .content-title {
    //     margin-left: 2.3%;
    // }
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #D2D9AE;
}

input:focus+.slider {
    box-shadow: 0 0 1px #D2D9AE;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
    .email-template-info {
        display: flex;
        align-content: center;
        justify-content: flex-start;
        width: 100%;

        .email-template-info-row {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;

            .template-label {
                width: 100%;
            }
            
            .back-button {
                border-radius: variables.$borderRadius;
                width: 10%;
                height: 45px;
                color: variables.$white;
                background-color: #8b8b8b;
                transition: 0.3s ease-in-out;

                &:hover {
                    background-color: variables.$midGray;
                    color: variables.$black;
                }
            }
            
            .field {
                padding: 15px;
                outline: none;
                width: 90%;
                margin: 20px;
                border: 1px solid variables.$white;
                border-radius: variables.$borderRadius;
                color: variables.$white;
                background-color: #595959;
                box-shadow: 1px 1px 3px variables.$black;
                transition: 0.2s ease-in-out;

                &::placeholder {
                    color: variables.$white;
                }

                &:focus {
                    box-shadow: 0px 0px 8px 1px variables.$white;
                }
            }
            .paragraph {
                height: 12em;
                width: 90%;
                border: none;
                border-radius: variables.$borderRadius;
                outline: none;
                background-color: #595959;
                padding: 15px;
                margin: 20px;
           
                transition: 0.2s ease-in-out;
                border: 1px solid variables.$white;

                &::placeholder {
                    color: variables.$white;
                }

                &:focus {
                    box-shadow: 0px 0px 8px 1px variables.$white;
                }
            }
        }
    }

    .email-template-files {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-start;
        width: 100%;

        b {
            font-size: 18px;
            margin-bottom: 16px;
            width: 100%;
        }

        .files {
            min-width: 50%;
            max-width: 100%;
            margin-bottom: 20px;
        }

        .files-label {
            width: 100%;
        }
    }

    .email-template-submit {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 25px;
        margin-bottom: 16px;
        .paragraph {
            height: 12em;
            width: 90%;
            border: none;
            border-radius: variables.$borderRadius;
            outline: none;
            background-color: #595959;
            padding: 15px;
            transition: 0.2s ease-in-out;
            border: 1px solid variables.$white;

            &::placeholder {
                color: variables.$white;
            }

            &:focus {
                box-shadow: 0px 0px 8px 1px variables.$white;
            }
        }

        .submit-btn {
            border-radius: variables.$borderRadius;
            width: 10%;
            height: 45px;
            color: variables.$white;
            background-color: #8b8b8b;
            transition: 0.3s ease-in-out;

            &:hover {
                background-color: variables.$midGray;
                color: variables.$black;
            }
        }
    }
}