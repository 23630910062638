@use "../styles/variables";

.old-staffing-page {
  background-image: linear-gradient(variables.$primaryGreen, variables.$black);
  min-height: 100vh;
}

// Staffing Profile

.staffing-profile,
.profile-metrics {
  width: 50%;
}

.staffing {
  &__sidebar {
    position: relative;
    box-sizing: border-box;
    width: 23%;
    transition: all 0.3s;

    &--collapsed {
      box-sizing: border-box;
      width: 20px;

      & > div {
        visibility: hidden;
      }
    }
  }

  &__content {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 0 25px 25px;
    width: 77%;
    transition: all 0.3s;

    &--collapsed {
      width: calc(100% - 20px);
    }
  }

  &__profile {
    box-sizing: border-box;
    height: calc(100vh - 292px);
    width: calc(38.5vw - 40px);
    min-width: calc(38.5vw - 40px);
    transition: all 0.3s;

    &--collapsed {
      width: calc(47.5vw);
    }
  }

  &__table {
    width: 50%;
    transition: all 0.3s;

    &--collapsed {
      width: calc(52.5vw - 25px);
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.profile-status {
  overflow-wrap: break-word;
  width: 100%;
}

.staffing-profile-header {
  background-color: variables.$primaryGreen;
  border-right: 1px solid variables.$lightGray50;
  border-top: 1px solid variables.$lightGray50;
  border-top-right-radius: 10px;
  color: variables.$white;
  font-family: variables.$magistralCondMed;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem;
  min-height: 58px;
  padding: 16px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
}

.profile-section-header {
  color: variables.$highlightGreen;
  font-family: variables.$magistralCondMed;
  font-size: 1.15rem;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.profile-info {
  margin-bottom: 8px;
}

.profile-automation-container {
  color: variables.$white;
  height: auto;
  min-height: 100px;
  width: auto;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.column {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 50%;
}

.staffing-comms__status {
  position: absolute;
  right: 3px;
  bottom: 5px;
  display: flex;

  &--delivered {
    right: 6px;
  }

  & img {
    width: 25px;
    height: 25px;
  }
}
