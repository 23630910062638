.quill {
  background-color: #fff;
  font-family: inherit;
  border-radius: 10px;
  
  & .ql-container, .ql-editor{
    min-height: 150px;  
  }
  
  & .ql-container{
    border: none;
  }
  & .ql-toolbar{
    border-radius: 10px 10px 0px 0px;
    background-color: white;
    font-family: inherit;
    border: none;
  }
  & .ql-editor {
    font-family: inherit;
    
    & p {
      font-size: 16px;
    }
  }
}
